import React, {useContext, useState, useEffect } from 'react';
import { Grid2, Avatar, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, Badge, MenuItem, Menu, IconButton, Skeleton, Divider } from '@mui/material';
import { Home, CalendarToday, AccountBalance, BusinessCenter, InsertChart, ShoppingCart, Settings, Assignment, HeadsetMic, Layers } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchBar from '../widgets/dashboard/SearchBar';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { getUnreadMessages } from '../../api/messaging';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getUser } from '../../api/userApi';



const drawerWidth = 280;

// Custom styles for the "NEW" badge
const NewBadge = styled('div')({
  fontSize: '10px',
  color: '#fff',
  backgroundColor: '#ff4757',
  padding: '2px 8px',
  borderRadius: '10px',
  marginLeft: 'auto',
});

const ProfileAvatar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Get the current location
  const user_type = localStorage.getItem('user_type');
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [photoURL, setPhotoURL] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // State for the dropdown menu
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null); // State for notification dropdown
  const [notifications, setNotifications] = useState([]);


  
  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if(!user_type) {
      navigate('/logout');
    }
    fetchUser();
    if (user_type === 'creator') {
    fetchNotifications();
    };
 
  }, []);

  const fetchUser = async () => {
      const auth = getAuth();
      const userId = auth.currentUser.uid;
      console.log(userId);
      await getUser(userId).then((response) => {
        const userData = response.result;
        console.log(userData);
        setUserDetails(userData);
        setPhotoURL(userData.profile_image);
      });
    
       
      setIsLoading(false);
      };



  const fetchUnreadMessages = async () => {
    const unreadMessages = await getUnreadMessages();
    setUnreadMessages(unreadMessages);
    console.log(unreadMessages.messagesCount);
    setUnreadMessageCount(unreadMessages.messagesCount);

  }

  const fetchNotifications = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = auth.currentUser.uid;
    const idToken = await user.getIdToken();
    const response = await axios.get(`https://ostendo.nucleotto.com/api/events/get?creatorId=${userId}`,
      {
        headers: {
         'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    if (!response) {
      console.error('Error fetching notifications:', response);
      return;
    }
    const dummyNotifications = response.data;
    setNotifications(dummyNotifications || []);
  };


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      navigate('/logout');
    });
    handleMenuClose();
  };

  const handleSettings = () => {
    navigate('/settings');
    handleMenuClose();
  };

  const handleProfile = () => {
    if (user_type === 'creator') {
    navigate('/creators/profile');
    } else {
    navigate('/profile');
    }
    handleMenuClose();
  };


  const handleMessaging = () => {
    navigate('/messaging');
    handleMenuClose();
  };

  const handleNotificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationAnchorEl(null);
  };



  return (
    <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
      <Grid2 item xs></Grid2>
      <Grid2 item>
        <Grid2 container alignItems="center" spacing={2}>
          {/* Notification Icon */}
          <Grid2 item>
            <IconButton onClick={handleNotificationMenuOpen}>
              <Badge badgeContent={unreadMessageCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={notificationAnchorEl}
              open={Boolean(notificationAnchorEl)}
              onClose={handleNotificationMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {/* Notification List */}
              <List>
                  {notifications && notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <ListItem key={notification.id}>
                        <ListItemText
                          primary={notification.message}
                          secondary={new Date(notification.timestamp).toLocaleString()}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary="No notifications" />
                    </ListItem>
                  )}
                </List>
            </Menu>
          </Grid2>

          {/* Avatar with Clickable Dropdown */}
          <Grid2 item>
            <IconButton onClick={handleMenuOpen}>
            
                  <Avatar alt="Profile Image" src={photoURL} />
                
            
            </IconButton>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                padding: '8px', // Add padding to the menu
                borderRadius: '20px', // Add rounded corners
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional: add subtle shadow
              }}
            >
              <Grid2 sx={{ marginLeft: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', marginRight: 2 }}>
                  {userDetails.email}
                </Typography>
                <Typography variant="subtitle2" sx={{}}>{user_type}</Typography>
              </Grid2>
              <Divider sx={{ my: 2 }} />

              <MenuItem onClick={handleProfile}>
                <Typography variant="subtitle2" sx={{}}>
        Settings
                </Typography>
              </MenuItem>

      
              <Divider sx={{ my: 2 }} />

              <MenuItem onClick={handleLogout}>
                <LogoutIcon sx={{ mr: 1 }} />
                <Typography variant="subtitle2" sx={{}}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ProfileAvatar;
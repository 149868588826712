import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Button,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage';
import { useNavigate } from 'react-router-dom'; 

// A row component for each folder which can expand to show its files
const FolderRow = ({ folderRef, applicationId, campaignId }) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const storage = getStorage();
  const currentUser = getAuth().currentUser.uid;
  const navigate = useNavigate(); // Initialize useNavigate

  const handleToggle = async () => {
    // When expanding the folder, fetch its file list
    if (!open) {
      try {
        const res = await listAll(folderRef);
        // Retrieve metadata for each file; adjust as needed to parse your file naming convention
        const fileData = await Promise.all(
          res.items.map(async (itemRef) => {
          const metadata = await getMetadata(itemRef);
          const url = await getDownloadURL(itemRef);
            return {
              name: itemRef.name,
              url: url,
              size: metadata.size,
              uploadedAt:  metadata.timeCreated, // Upload timestamp
              type: metadata.contentType,
              taskAction: itemRef.name.split('_')[0],
            };
            
          })
        );
        setFiles(fileData);
        console.log("Files:", fileData);
      } catch (error) {
        console.error('Error listing files:', error);
      }
    }
    setOpen(!open);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {currentUser === folderRef.name ? <strong>Your Files</strong> : <strong>Creator Files</strong> }
        </TableCell>
    
     
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="files">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>File Name</strong></TableCell>
                    <TableCell><strong>Type</strong></TableCell>
                    <TableCell><strong>Size</strong></TableCell>
                    <TableCell><strong>Uploaded At</strong></TableCell>
                    <TableCell><strong>Actions</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>{file.taskAction}</TableCell>
                      <TableCell>{file.type}</TableCell>
                      <TableCell>{(file.size / 1024).toFixed(2)} KB</TableCell>
                      <TableCell>{new Date(file.uploadedAt).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            if (file.type && file.type.includes('video')) {
                              navigate(`/review-creative?cid=${campaignId}&app=${applicationId}&url=${encodeURIComponent(file.url)}` );
                            } else {                          
                              window.open(file.url, '_blank')}}}
                        >
                          View
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ ml: 1 }}
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = file.url;
                            link.download = file.name;
                            link.click();
                          }}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {files.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No files in this folder.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AdminApplicationFilesTable = ({ applicationId, refresh, campaignId }) => {
  const [folderRefs, setFolderRefs] = useState([]);
  const storage = getStorage();
  const [key, setKey] = useState(0);
  console.log("ApplicationID in ADMINAPP TABLE:", applicationId)



  useEffect(() => {
    async function fetchFolders() {
      // Reference to the application folder
      const listRef = ref(storage, `application_files/${applicationId}/`);
      try {
        const res = await listAll(listRef);
        // Folders are returned in the prefixes array
        setFolderRefs(res.prefixes);
      } catch (error) {
        console.error('Error fetching folders:', error);
      }
    }
    if (applicationId) {
      fetchFolders();
    }
  }, [applicationId, storage, refresh]);

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>Folder Name</strong></TableCell>

           
          </TableRow>
        </TableHead>
        <TableBody>
          {folderRefs.map((folderRef) => (
            <FolderRow key={folderRef.fullPath} folderRef={folderRef} campaignId={campaignId} applicationId={applicationId} />
          ))}
          {folderRefs.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No folders found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default AdminApplicationFilesTable;

// ApplicationDataTable.jsx
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  Box
} from '@mui/material';

const ApplicationDataTable = ({ applications }) => {
  if (!applications || applications.length === 0) {
    return (
      <Typography variant="body1">
        No applications available.
      </Typography>
    );
  }

  // Format date for display
  const formatDate = (dateStr) => {
    if (!dateStr) return '-';
    const date = new Date(dateStr);
    return date.toLocaleString();
  };

  
  const renderPlacements = (placements) => {
    if (!placements || placements.length === 0) {
      return '-';
    }
    return placements.map((p, index) => (
      <Box key={index} sx={{ mb: 1, p: 0.5, border: '1px solid #eee', borderRadius: 1 }}>
        <Typography variant="caption">
          <strong>ID:</strong> {p.placementId} <br />
          <strong>Fee:</strong> ${p.proposedFee} <br />
          <strong>Status:</strong> {p.status || 'N/A'}
        </Typography>
      </Box>
    ));
  };




  const getTotalProposedFee = (placements) => {
    return placements?.reduce((acc, p) => acc + (Number(p.proposedFee) || 0), 0) || 0;
  };

  return (
     <Box>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Application Status
          </Typography>
    <TableContainer component={Paper}>
      <Table aria-label="Applications Table">
        <TableHead>
          <TableRow>
       
            <TableCell><strong>Application Date</strong></TableCell>
            <TableCell><strong>Proposed Fee</strong></TableCell>
            <TableCell><strong>Brands Comments</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
        
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((app) => (
            <TableRow key={app.applicationId || app._id}>

              <TableCell>{formatDate(app.applicationDate)}</TableCell>
              <TableCell>${getTotalProposedFee(app.placementsAppliedTo)}</TableCell>
              <TableCell>{app.comments || '-'}</TableCell>
              <TableCell>{app.status || '-'}</TableCell>
        
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
};

export default ApplicationDataTable;

// TaskPanel.jsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  Divider,
  CircularProgress
} from '@mui/material';
import { updateTask } from '../../../../api/tasks';

const TaskPanelAdmin = ({taskData, triggerTaskRefresh, application}) => {
  const [tasks, setTasks] = useState(taskData);
  const [loadingTaskUpdate, setLoadingTaskUpdate] = useState(false);
  const [trackingAvailable, setTrackingAvailable] = useState(false);

  useEffect(() => {
    if (application && application.aggregatedTaskData) {
      const aggregated = application.aggregatedTaskData;
      const sendProductTask = aggregated.find(task => task.taskName === 'Send Product');
      if (sendProductTask && sendProductTask.carrier && sendProductTask.trackingNumber) {
        setTrackingAvailable(true);
      } else {
        setTrackingAvailable(false);
      }
    }
  }, [application]);

  const handleToggleTask = async (task) => {
    setLoadingTaskUpdate(true);
    let status;
    if (task.status === 'Completed') {
      status = 'Pending';
    } else {
      status = 'Completed';
    }
    await updateTask(status, task._id);
    await triggerTaskRefresh();
    setLoadingTaskUpdate(false);
  };


  return (
    <Box>
    
      
      <List>
        {taskData.map((task) => (
          <React.Fragment key={task._id}>
            <ListItem button onClick={() => handleToggleTask(task)}>
              <ListItemIcon>
                {loadingTaskUpdate  ? (
                  <CircularProgress size={10} />
               
                ) : ( <Checkbox
                  edge="start"
                  checked={task.status === 'Completed'}
                  tabIndex={-1}
                  disableRipple
                />)}
              </ListItemIcon>
              <ListItemText primary={task.taskName} />
             
          
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default TaskPanelAdmin;

// CampaignInteractionScreen.jsx
import React from 'react';
import { Container, Grid2, Paper, Typography, Box, CssBaseline } from '@mui/material';
import ProfileAvatar from '../../../navigation/profileAvatar';
import Sidebar from '../../../navigation/MainNav';


import ChatPanel from './ChatPanel';
import TaskPanel from './TaskPanel';
import CalendarPanel from './CalendarPanel';
import AdminActionSelector from './AdminActionSelector';

const CampaignInteractionScreen = () => {
  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Sidebar />
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Container maxWidth="xxl">
      
      <Box>
        <ProfileAvatar />
   </Box>

      <Typography variant="h4" gutterBottom>
        Campaign Interaction
      </Typography>
      <Grid2 container spacing={2}>
        {/* Chat Panel */}
        <Grid2 item width="45%">
          <Paper
            elevation={3}
            sx={{ p: 2, height: '75vh', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="h6" gutterBottom>
              Chat
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <ChatPanel />
            </Box>
          </Paper>
        </Grid2>
        {/* Task/Reminder Panel */}
        <Grid2 item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, height: '75vh', overflowY: 'auto' }}
          >
            <Typography variant="h6" gutterBottom>
              Your Tasks & Reminders
            </Typography>
            <TaskPanel />
          </Paper>
        </Grid2>

        <Grid2 item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, height: '75vh', overflowY: 'auto' }}
          >
            <Typography variant="h6" gutterBottom>
            Calendar
            </Typography>
       

            <AdminActionSelector />
            <CalendarPanel />
          </Paper>
        </Grid2>

      </Grid2>

    </Container>
    </Box>
    </Box>
  );
};

export default CampaignInteractionScreen;

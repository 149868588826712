import React from 'react';
import { 
  Box, 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Chip, 
  Button 
} from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';
import { statusColors } from '../../../../config/status';

const CampaignSettingsView = () => {
const navigate = useNavigate();
 const location = useLocation();
 const { campaignDetails, activeTab: initialActiveTab } = location.state || {};

  // Helper to render a labelled value; note that the value is computed safely
  // using the pattern: if it's an array, join it; otherwise, use it directly.
  const renderField = (label, value) => (
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">
        {Array.isArray(value) ? value.join(', ') : value || '-'}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          

          {/* Campaign Header */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
             <Chip
              label={campaignDetails.status}
              sx={{
                ml: 2,
                backgroundColor:
                  statusColors[campaignDetails.status]?.backgroundColor ||
                  statusColors.default.backgroundColor,
                color:
                  statusColors[campaignDetails.status]?.color ||
                  statusColors.default.color,
              }}
            />  {campaignDetails.campaignName}
            </Typography>
           
          </Box>

          {/* Two-column layout for campaign details */}
          <Grid container spacing={3}>
            {/* Overview Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Overview
                </Typography>
                {renderField(
                  'Advertiser',
                  Array.isArray(campaignDetails.advertiserName)
                    ? campaignDetails.advertiserName.join(', ')
                    : campaignDetails.advertiserName
                )}
                {renderField(
                  'Product Information',
                  Array.isArray(campaignDetails.productInformation)
                    ? campaignDetails.productInformation.join(', ')
                    : campaignDetails.productInformation
                )}
                {renderField(
                  'Campaign Summary',
                  Array.isArray(campaignDetails.campaignSummary)
                    ? campaignDetails.campaignSummary.join(', ')
                    : campaignDetails.campaignSummary
                )}
                {renderField(
                  'Campaign Objective',
                  Array.isArray(campaignDetails.campaignObjective)
                    ? campaignDetails.campaignObjective.join(', ')
                    : campaignDetails.campaignObjective
                )}
              </Paper>
            </Grid>

            {/* Campaign Terms Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Campaign Terms
                </Typography>
                {renderField(
                  'Campaign Term',
                  Array.isArray(campaignDetails.campaignTerm)
                    ? campaignDetails.campaignTerm.join(', ')
                    : campaignDetails.campaignTerm
                )}
                {renderField('Start Date', campaignDetails.startDate)}
                {renderField('End Date', campaignDetails.endDate)}
                {renderField('Content Deadline', campaignDetails.contentDeadlines)}
                {renderField(
                  'Remuneration / Product Value',
                  Array.isArray(campaignDetails.productValue)
                    ? campaignDetails.productValue.join(', ')
                    : campaignDetails.productValue
                )}
              </Paper>
            </Grid>

            {/* Target Audience Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Target Audience
                </Typography>
                {renderField(
                  'Age Range',
                  campaignDetails.targetAudienceAge
                )}
                {renderField(
                  'Gender',
                  campaignDetails.targetAudienceGender
                )}
                {renderField(
                  'Primary Location',
                  campaignDetails.targetPrimaryAudienceLocation
                )}
                {renderField(
                  'Secondary Locations',
                  campaignDetails.targetSecondaryAudienceLocation
                )}
              </Paper>
            </Grid>

            {/* Influencer Profile Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Influencer Profile
                </Typography>
                {renderField(
                  'Age Range',
                  campaignDetails.influencerAgeRange
                )}
                {renderField(
                  'Gender',
                  campaignDetails.influencerGender
                )}
                {renderField(
                  'Location',
                  campaignDetails.influencerLocation
                )}
                {renderField(
                  'Category',
                  campaignDetails.influencerCategory
                )}
              </Paper>
            </Grid>

            {/* Deliverables Section */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Deliverables
                </Typography>
                {renderField(
                  'Placement Objective',
                  campaignDetails.placementObjective
                )}
                {renderField(
                  'Content Approval Needed',
                  campaignDetails.contentApprovalNeeded
                )}
                {/* Additional deliverable details could be added here */}
              </Paper>
            </Grid>

            {/* Exclusivity Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Exclusivity
                </Typography>
                {renderField(
                  'Category Exclusivity',
                  campaignDetails.categoryExclusivity
                )}
                {renderField(
                  'Territory Exclusivity',
                  campaignDetails.territoryExclusivity
                )}
                {renderField(
                  'Exclusivity Period (months)',
                  campaignDetails.exclusivityPeriod
                )}
              </Paper>
            </Grid>

            {/* Campaign Expectations Section */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Campaign Expectations
                </Typography>
                {renderField(
                  'Content Revisions',
                  campaignDetails.contentRevisions
                )}
                {renderField(
                  'Performance Metrics',
                  campaignDetails.performanceMetrics
                )}
                {renderField(
                  'Reporting Required',
                  campaignDetails.reportingRequired
                )}
                {renderField(
                  'Paid Disclosure',
                  campaignDetails.paidDisclosure
                )}
                {renderField(
                  'Brand Tagging',
                  campaignDetails.brandTagging
                )}
                {renderField(
                  'Interactivity',
                  campaignDetails.interactivity
                )}
                {renderField(
                  'Final Comments',
                  campaignDetails.finalComments
                )}
              </Paper>
            </Grid>

            {/* UGC Ads – Additional Details */}
            {campaignDetails.jobType === 'UGC Ads' && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    UGC Ad Details
                  </Typography>
                  {renderField(
                    'Video Type',
                    campaignDetails.videoType
                  )}
                  {renderField(
                    'Video Length',
                    campaignDetails.videoLength
                  )}
                  {renderField(
                    'Video Content Usage',
                    campaignDetails.videoContentUsage
                  )}
                  {renderField(
                    'Video Aspect Ratio',
                    campaignDetails.videoAspectRatio
                  )}
                  {renderField(
                    'Additional Hook',
                    campaignDetails.additionalHook
                  )}
                  {renderField(
                    'Additional CTA',
                    campaignDetails.additionalCTA
                  )}
                  {renderField(
                    'Captions',
                    campaignDetails.captions
                  )}
                  {renderField(
                    'Royalty Free Music',
                    campaignDetails.royaltyFreeMusic
                  )}
                  {renderField(
                    'Raw Assets',
                    campaignDetails.rawAssets
                  )}
                </Paper>
              </Grid>
            )}

            {/* Event Attendance – Additional Details */}
            {campaignDetails.jobType === 'Event Attendance' && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Event Details
                  </Typography>
                  {renderField(
                    'Location',
                    campaignDetails.location
                  )}
                  {renderField(
                    'Time Arrival',
                    campaignDetails.timeArrival
                  )}
                  {renderField(
                    'Time Sensitive',
                    campaignDetails.timeSensitive
                  )}
                  {renderField(
                    'Time Flexibility',
                    campaignDetails.timeFlexibility
                  )}
                  {renderField(
                    'Event Type',
                    campaignDetails.eventType
                  )}
                  {renderField(
                    'Dress Code',
                    campaignDetails.dressCode
                  )}
                  {renderField(
                    'Perks',
                    campaignDetails.perks
                  )}
                  {renderField(
                    'Compensation',
                    campaignDetails.compensation
                  )}
                  {renderField(
                    'Preferred Platforms',
                    campaignDetails.preferredPlatforms
                  )}
                </Paper>
              </Grid>
            )}
          </Grid>

        {/*
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              onClick={() => navigate(`/campaigns/edit/${campaignDetails._id}`)}
              sx={{ mr: 2 }}
            >
              Edit Campaign
            </Button>
            <Button variant="outlined" onClick={() => navigate('/campaigns')}>
              Back to Campaigns
            </Button>
          </Box>

          */}

        </Container>
      </Box>
    </Box>
  );
};

export default CampaignSettingsView;

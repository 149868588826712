// ChatPanel.jsx
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { postMessage, getApplicationMessages } from '../../../../api/messaging';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const ChatPanel = ({application}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [applicationId, setApplicationId] = useState(() => {
    return application?._id || localStorage.getItem('applicationId') || '';
  });
  const chatWindowRef = useRef(null); // Ref to the chat window


  
  useEffect(() => {
    if (application && application._id) {
      setApplicationId(application._id);
      localStorage.setItem('applicationId', application._id);
    }
  }, [application]);

  useEffect(() => {
  const interval = setInterval(() => {
    getApplicationMessages(applicationId).then((data) => {
      setMessages(data.messages || []);
    });
  }, 5000);

  // Cleanup on unmount
  return () => clearInterval(interval);
}, [applicationId]);

useEffect(() => {
  // Scroll to bottom after messages load or update
  if (chatWindowRef.current) {
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }
}, [messages]); // Scroll when messages change

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const year = String(date.getFullYear()).slice(2); // Get last two digits of year
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

  const handleSendMessage = async () => {
  setSending(true);
    if (!newMessage.trim()) {
      setSending(false);
      return;
    }
    

    const messageData = {
      applicationId: applicationId, 
      campaignId: application.campaignId,
      campaignName: application.campaignName,
      text: newMessage,
      to: "Admin", 
      from: "Creator",
      
     };
     try {
   await postMessage(messageData);
   setMessages(prevMessages => [...prevMessages, { ...messageData, lastUpdated: new Date(), _id: Date.now(), from: "Admin" }]); // Simulate new message
   setNewMessage('');
 } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      toast.success('Message sent successfully!', {
        position: 'top-right',
        autoClose: 3000, // Close after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setSending(false);
    }
  };

  useEffect(() => {
    // Scroll to bottom after messages load or update
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]); // Scroll when messages change



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
     <Box
        ref={chatWindowRef} // Attach the ref to the chat window
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', mb: 2 }}
      >
        
          {messages.map((msg) => (
           
                 <Paper
                             key={msg._id}
                             sx={{
                               p: 1,
                               mb: 2,
                               backgroundColor: msg.from === 'Admin' ? 'lightgreen': 'lightblue',
                               maxWidth: '60%',
                               wordBreak: 'break-word',
                               alignSelf: msg.from === 'Admin' ? 'flex-start' : 'flex-end',
                             }}
                           >
                <ListItemText primary={msg.text} />

           <Typography variant="caption" align="right" sx={{ fontStyle: 'italic', color: 'grey' }}>
                {formatDate(msg.lastUpdated)}</Typography>
              </Paper>
   
          ))}

      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
        />
        <IconButton color="primary" onClick={handleSendMessage} disabled={sending || !newMessage.trim()}>
          {sending ? (
            <CircularProgress size={12} />
          ) : (<SendIcon />)}

        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatPanel;

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

const VideoPlayer = () => {
  const user_type = localStorage.getItem('user_type');
  const [searchParams] = useSearchParams(); 
  const videoUrl = searchParams.get('url'); // Get the video URL from the location object
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleProgress = (state) => {
    setPlayedSeconds(state.playedSeconds);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  return (
    <div>
      <ReactPlayer
        url={videoUrl}
        controls
        onProgress={handleProgress}
        onDuration={handleDuration}
        width="600px"
      />

      <div>
        <p>Played Time: {playedSeconds.toFixed(2)} seconds</p>
        <p>Duration: {duration.toFixed(2)} seconds</p>
      </div>
    </div>
  );
};

export default VideoPlayer;

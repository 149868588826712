// CreatorProfilePage.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  CircularProgress,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  Paper,
  Chip
} from '@mui/material';
import Sidebar from '../../../components/navigation/MainNav';
import ProfileAvatar from '../../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import CreatorDetailCard from '../../../components/widgets/creators/ProfileWidget';
import '../../../pages/welcome/SocialConnectPage.css';


const CreatorProfilePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const [isMetaConnected, setIsMetaConnected] = useState(false);
  const [isMetaConnecting, setIsMetaConnecting] = useState(false);
  const [isTikTokConnecting, setIsTikTokConnecting] = useState(false);
  const [error, setError] = useState(null);
  const [connectedMessage, setConnectedMessage] = useState('Meta accounts connected successfully!, It may take a few hours for us to retrieve the appropriate data from Meta.');


  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout');
    } else {
      getUser();
    }
  }, [navigate]);

  const getUser = async () => {
    try {
      if (user) {
        const accessToken = await user.getIdToken();
        const userId = user.uid;
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/user/getUser?userId=${userId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUserData(response.data.result || {});
        console.log('User data:', response.data.result);
        
        if (response.data.result.metaTokenExpiresAt) {
          setIsMetaConnected(true);
        }
      
      }


    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  };



    const handleMetaConnect = async () => {
      console.log("connecting to Meta started")
      try {
        setIsMetaConnecting(true);
          const auth = getAuth();
          const user = auth.currentUser;
          if (user) {
          const idToken = await user.getIdToken();
          const backendUrl = 'https://ostendo.nucleotto.com';
  
          const response = await axios.post(
          `${backendUrl}/api/creator/connect/meta?userId=${user.uid}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
  
        const authUrl = response.data.authUrl;
        console.log("Auth URL", authUrl);
        const width = 600;
        const height = 700;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
  
        const authWindow = window.open(
          authUrl,
          'MetaAuth',
          `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`
        );
  
        const handleAuthResponse = (event) => {
          console.log("event data", event.data)
          if (event.origin !== window.location.origin) return;
  
          if (event.data.type === 'meta_auth_response') {
            const { code, error } = event.data;
  
            if (error) {
              console.error('Meta authentication error:', error);
              alert('Failed to connect Meta accounts.');
            } else if (code) {
              console.log(code);
              handleCodeExchange(code);
            }
  
            window.removeEventListener('message', handleAuthResponse);
            authWindow.close();
          }
        };
  
        window.addEventListener('message', handleAuthResponse);
  
        const checkWindowClosed = setInterval(() => {
          if (authWindow.closed) {
            clearInterval(checkWindowClosed);
            // Instead of removing the listener immediately, consider leaving it active
            // for a short grace period to allow any pending message to come through.
            setTimeout(() => {
              window.removeEventListener('message', handleAuthResponse);
              setIsMetaConnecting(false);
            }, 1000);
          }
        }, 500);
          }} catch (error) {
        console.error('Error initiating Meta connection:', error);
        alert('Failed to initiate Meta connection.');
        setIsMetaConnecting(false);
      }
    };
  
    // Exchange authorization code via backend
    const handleCodeExchange = async (code) => {
      try {
          const auth = getAuth();
          const user = auth.currentUser;
          if (user) {
              const idToken = await user.getIdToken();
          
        const backendUrl = 'https://ostendo.nucleotto.com'; // Replace with your backend URL
        console.log("Code exchange started with user ID");
        const response = await axios.post(`${backendUrl}/api/creator/connect/meta-exchange?userId=${user.uid}`, 
       { code },
       {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          
        });
  if (response.status === 201 || response.status === 200) {  
          setIsMetaConnected(true);
      //    await fetchMetaData(); 
      //    alert('Meta accounts connected successfully!');
        } else {
          throw new Error(response.data.error || 'Failed to connect Meta account');
        }
      }} catch (error) {
        console.error('Error connecting Meta account:', error);
        alert('Failed to connect Meta accounts.');
      } finally {
        setIsMetaConnecting(false);
      }
    };
  
    // Placeholder for TikTok connection
    const handleTikTokConnect = () => {
      setIsTikTokConnecting(true);
      console.log('Initiating TikTok authentication');
      // Implement TikTok authentication here
      setIsTikTokConnecting(false);
    };
  



  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          {/* Profile Header */}
          <Box display="flex" alignItems="center" mb={4}>
            <ProfileAvatar />
            <Box ml={2}>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                {userData.fullName || userData.username || 'Your Name'}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {userData.email || 'youremail@example.com'}
              </Typography>
            </Box>
          </Box>

          {/* Basic Details Section */}
          <Box mb={4}>
          <CreatorDetailCard userData={userData} />
          </Box>

          
      <div className="connect-wrapper">
        <h1>Connect Your Social Media</h1>

      
          <div className="connect-options">
            <div className="connect-card">
              <div className="platform-logo meta-logo">
                <span>M</span>
              </div>
              <h2>Meta Platforms</h2>
              <p>Connect your Facebook and Instagram accounts</p>
              <button
                className={isMetaConnected ? "disabled-btn connect-btn" : "connect-btn meta-btn"}
                onClick={handleMetaConnect}
                disabled={isMetaConnecting || isMetaConnected}
              >
                {isMetaConnecting ? 'Connecting...' : isMetaConnected ? 'Connected' : 'Connect Meta'}
              </button>
            </div>

            <div className="connect-card">
              <div className="platform-logo tiktok-logo">
                <span>T</span>
              </div>
              <h2>TikTok</h2>
              <p>Connect your TikTok account</p>
              <button
                style={{marginTop: '1rem'}}
                className="connect-btn tiktok-btn"
                onClick={handleTikTokConnect}
                disabled={isTikTokConnecting}
            
              >
                {isTikTokConnecting ? 'Connecting...' : 'Coming soon'}
              </button>
            </div>
      
    

        
        {error && (
          <p style={{ color: 'red', marginBottom: '1rem' }}>
            Error: {error}
          </p>
        )}
{/*
    {isMetaConnected && (
          <p style={{ color: 'black', marginBottom: '1rem' }}>
            {connectedMessage}
          </p>
        )}
*/}
      
      
       
      </div>
    </div>


          {/* Existing Creator Detail Card */}
          <Box mb={4}>
           
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreatorProfilePage;

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Badge,
  CssBaseline, 
  Container, 
  Typography, 
  Button, 
  Grid2, 
  Chip, 
  Skeleton,
  CircularProgress, 
  Paper 
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { updateCampaign } from '../../api/campaignsApi';
import { statusColors } from '../../config/status';

import PaidInfluencerCampaignDetail from '../../components/widgets/campaigns/PaidInfluencers/CampaignDetails';
import ProductSeedingCampaignDetail from '../../components/widgets/campaigns/ProductSeeding/CampaignDetails';
import PaidInfluencersMediaPlan from '../../components/widgets/campaigns/PaidInfluencers/MediaPlan';
import BudgetOverview from '../../components/widgets/campaigns/BudgetOverview';
import ChatPanelAdmin from '../../components/widgets/campaigns/PaidInfluencers/ChatPanelAdmin';
import Calendar from 'react-calendar/dist/cjs/Calendar.js';
import CalendarPanel from '../../components/widgets/campaigns/jobScreen/CalendarPanel';

import TaskPanelAdmin from '../../components/widgets/campaigns/PaidInfluencers/TaskPanelAdmin';
import AdminApplicationFilesTable from '../../components/widgets/campaigns/PaidInfluencers/AdminApplicationFiles';
import AdminActionSelector from '../../components/widgets/campaigns/jobScreen/AdminActionSelector';

const MediaPlan = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [placementData, setPlacementData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState(0);
  const user_type = localStorage.getItem('user_type');
  const [tasks, setTasks] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [refreshFiles, setRefreshFiles] = useState(false);

  const [jobs, setJobs] = useState([]);
  const { jobId } = useParams();
  console.log(jobId);


  useEffect(() => {
    fetchCampaignDetails();
    getPlacements();
    getApplications();

    console.log("ApplicationData", applicationData)
  
  }, []);


  useEffect(() => {
    
    
  const getTasks = async () => {
   try {
      const auth = getAuth();
      const user = auth.currentUser;
      const application = selectedApplication;
      const type = "Creator";
      if (user) { 
        const idToken = await user.getIdToken();
        const response = await axios.get(`https://ostendo.nucleotto.com/api/tasks/get?application=${application}`,
        
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );  
        setTasks(response.data.tasks);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
    } 
  }

  getTasks();
  }, [selectedApplication]);




  const fetchCampaignDetails = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${jobId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(response.data.jobs[0]);
        setCampaignDetails(response.data.jobs[0] || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlacements = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/placement/list',
          { campaignId: jobId },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        
        setPlacementData(response.data.placements || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getApplications = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/application/mediaplan',
          { campaignId: jobId },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const applications = response.data.applications || [];
        const filteredApplications = applications.filter(application => application.status === 'Accepted');
        setApplicationNumber(filteredApplications.length);
        setApplicationData(filteredApplications); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      setApplicationNumber(0);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshTasks = async () => {
    try {
       const auth = getAuth();
       const user = auth.currentUser;
       const application = selectedApplication;
       const type = "Creator";
       if (user) { 
         const idToken = await user.getIdToken();
         const response = await axios.get(`https://ostendo.nucleotto.com/api/tasks/get?application=${application}`,
         
           {
             headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${idToken}`,
             },
           }
         );  
         setTasks(response.data.tasks);
       }
     } catch (error) {
       console.error('Error fetching tasks:', error);
     } finally {
     } 
   }

 

  useEffect(() => {
  console.log("selected Application", selectedApplication);    

  }, [selectedApplication]);


  const triggerRefreshApplications = async () => {
    getApplications();
  };


  const totalAcceptedProposedFees = applicationData
  .filter(app => app.status === 'Accepted')
  .reduce((acc, app) => {
    const appTotal = app.placementsAppliedTo?.reduce((sum, placement) => sum + (Number(placement.proposedFee) || 0), 0) || 0;
    return acc + appTotal;
  }, 0);


  const handlePublish = async (e) => {
    setIsPublishLoading(true);
    e.preventDefault();

    const nextStatus = campaignDetails.status === 'Live' ? 'Paused' : 'Live';

    const updatedCampaignData = {
      status: nextStatus,
    };

    const submitCampaign = await updateCampaign(jobId, updatedCampaignData);

    console.log(submitCampaign);
    if (submitCampaign) {
      window.location.reload(); // Refresh the page after successful submission
    }

    setIsPublishLoading(false); // Stop loading
  };

  const handleEditCampaign = async () => {
    navigate(`/campaigns/edit/${jobId}`, { state: { campaignDetails } });
  };

  const handleViewCampaignDetails = async () => {
    if(campaignDetails.jobType === 'Paid Influencers') {
    navigate(`/campaigns/detail/${jobId}/settings`, { state: { campaignDetails } });
    }
  };

  const handleDuplicateCampaign = async () => {
    const duplicateCampaignData = campaignDetails;
    navigate(`/campaigns/create`, { state: { duplicateCampaignData } });
  };

  const viewApplications = async () => {
    navigate('/campaigns/applications', { state: { campaignDetails, placementData, applicationData } });
  };

  
  const triggerApplicationRefresh = async () => {
//    await getApplication();
  } 

  const handleFileRefresh = () => {
    setRefreshFiles(prev => !prev);
  };

  const triggerTaskRefresh = async () => {
    await refreshTasks();

  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      {isLoading ? (
         <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '90%', ml: 10, mt: 20 }}>
         <CircularProgress />
       </Grid2> 
      ) : (
        <>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              
              <Box>
                <ProfileAvatar />
           </Box>

                {/* Title and Buttons */}
  
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        

                           

                              <Button
                                variant="contained"
                                onClick={handleViewCampaignDetails}
                                sx={{ borderRadius: '10px', mr: 2 }}
                              >
                                Settings
                              </Button>


                              <Button
                                variant="contained"
                                onClick={handlePublish}
                                sx={{ borderRadius: '10px' }}
                                disabled={isPublishLoading}
                              >
                                {isPublishLoading
                                  ? 'Processing...'
                                  : campaignDetails.status === 'Live'
                                  ? 'Pause'
                                  : 'Publish'}
                              </Button>
                        

                </Box>
                {/* Campaign Name and Advertiser Name */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4 }}>
              </Grid2>

    

              <BudgetOverview 
                 totalBudget={campaignDetails.totalBudget} 
                 totalAcceptedProposedFees={totalAcceptedProposedFees}
                 />
                 
         <Grid2 container spacing={2} sx={{ mt: 4 }}>
         <Grid2 item width="66%">
          <Paper
            elevation={3}
            sx={{ p: 2, height: '50vh', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="h6" gutterBottom>
              Chat
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <ChatPanelAdmin
              campaignId={campaignDetails._id}
              applications={applicationData}
              setSelectedApplication={setSelectedApplication} />
            </Box>
          </Paper>
        </Grid2>

        <Grid2 item width="33%">
          <Paper
            elevation={3}
            sx={{ p: 2, height: '50vh', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="h6" gutterBottom>
              Tasks
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
          <AdminActionSelector 
           application={selectedApplication}
           triggerApplicationRefresh={triggerApplicationRefresh} 
           triggerFileRefresh={handleFileRefresh} 
      
           />
           <TaskPanelAdmin taskData={tasks}
                triggerTaskRefresh={triggerTaskRefresh} />
            </Box>
          </Paper>
        </Grid2>
        

                 
        </Grid2>

        <AdminApplicationFilesTable
        applicationId={selectedApplication}
        refresh={refreshFiles}
        campaignId={jobId} />



                {campaignDetails.jobType === 'Paid Influencers' ? (
                <>
                <PaidInfluencersMediaPlan 
                applications={applicationData}
                triggerRefreshApplications={triggerRefreshApplications}
             
               />

              </>
               ) : null}
             
     
        
        
                        
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MediaPlan;

// PaidInfluencersDashboard.jsx
import React, {useState, useEffect} from 'react';
import { 
  Box,
  Container, 
  Typography,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,

 } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import YourAIAssessment from './YourAIAssessment';
import PlacementSummaryTable from './PlacementSummaryTable';
import CampaignSettings from './campaignSettings';
import { createApplication } from '../../../../../api/applicationApi';
import ApplicationDataTable from './ApplicationSummaryTable';
import PaidInfluencerJobDetailCreator from './CampaignJobDetails';


const PaidInfluencerCampaignDetailCreator = ({
  campaignData,
  influencers,
  placements,
  applications,
  spentBudget
}) => {

    const [viewAcceptedOpen, setViewAcceptedOpen] = useState(false);
    const [viewInvitedOpen, setViewInvitedOpen] = React.useState(false);
    const [viewApplicationsOpen, setViewApplicationsOpen] = React.useState(true);
    const [viewMediaPlanOpen, setViewMediaPlanOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [applyModalOpen, setApplyModalOpen] = useState(false);
    const [negotiationMessage, setNegotiationMessage] = useState('');
    const [applicationPlacements, setApplicationPlacements] = useState([]);
    const [isApplicationLoading, setIsApplicationLoading] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState('');
    const [applicationData, setApplicationData] = useState({});



    const handleOpenApplyModal = () => setApplyModalOpen(true);
    const handleCloseApplyModal = () => setApplyModalOpen(false);
    const navigate = useNavigate();
    
    const toggleViewInvited = () => {
        setViewInvitedOpen((prev) => !prev);
      };

      const toggleViewApplications = () => {
        setViewApplicationsOpen((prev) => !prev);
      };

      const toggleViewMediaPlan = () => {
      navigate(`media-plan`);
      };

     const toggleViewAccepted = () => {
        setViewAcceptedOpen((prev) => !prev);
      };

      const handleSubmitApplication = async () => {
        setIsApplicationLoading(true)
      const selectedPlacements = applicationPlacements.filter(
        (item) => item.selected
      );
      const applicationData = {
        campaignId: campaignData._id,
        campaignName: campaignData.campaignName,
        comments: negotiationMessage,
        placementsAppliedTo: selectedPlacements.map((item) => ({
          placementId: item._id,
          proposedFee: item.proposedFee
        }))
      };
  
      try {
        // Call your API (or prop callback) to submit the application
        await createApplication(applicationData);
        window.location.reload();
      } catch (error) {
        console.error('Error submitting application:', error);
      } finally {
        setApplyModalOpen(false);
      }
    };

    useEffect(() => {
      if (applications && applications.length) {
       setApplicationStatus(applications[0].status);
       setApplicationData(applications[0]);
      }
    }, [applications]);


      useEffect(() => {
        if (placements && placements.length) {
          setApplicationPlacements(
            placements.map((p) => ({
              _id: p._id,
              selected: true,
              proposedFee: p.fee || ''
            }))
          );
        }
      }, [placements]);


      const handlePlacementChange = (id, field, value) => {
        setApplicationPlacements((prev) =>
          prev.map((item) =>
            item._id === id ? { ...item, [field]: value } : item
          )
        );
      };


  return (
    <Container maxWidth="xl">
    <Typography variant="h6">{campaignData.campaignName}</Typography>
    <Chip label={campaignData.status} sx={{
          backgroundColor: campaignData.status === 'Live' ? 'green' : 'grey',
          color: 'white'
        }} />

{applicationStatus && (
  <Box sx={{ mt: 3, mb: 5 }}>
    <Chip label={`Application Status: ${applicationStatus}`} />
  </Box> 
)}

{applicationStatus === 'Accepted' ? (
 <PaidInfluencerJobDetailCreator  
 applications={applications}
 campaignData={campaignData} />
) : (

  <>


<Box sx={{ mb: 3, mt: 3 }}>
    <ApplicationDataTable applications={applications} />
    </Box>


  
        <Box sx={{ mb: 3, mt: 3 }}>
      <PlacementSummaryTable 
      placements={placements} />
            </Box>




            
<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button variant="contained" onClick={handleOpenApplyModal}>Apply for Campaign</Button>
      </Box>



            <Box sx={{ mb: 3, mt: 3 }}>
      <YourAIAssessment followers={175000} />
            </Box>



    <CampaignSettings 
    campaignData={campaignData}
    />
</>
  )}


      {/* Application Modal */}
      <Dialog open={applyModalOpen} onClose={handleCloseApplyModal} maxWidth="md" fullWidth>
        <DialogTitle>Apply for Campaign</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Please adjust your placement selections and proposed fees (if necessary), and enter a message if you wish to propose any amendments.
          </Typography>
          {/* List the placements with a checkbox and proposed fee input */}
          {applicationPlacements.map((placement) => (
            <Paper key={placement._id} variant="outlined" sx={{ p: 2, mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="subtitle1">
                    {placements.find(p => p._id === placement._id)?.platform || 'Platform'} –{' '}
                    {placements.find(p => p._id === placement._id)?.placementType || 'Placement Type'}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {placements.find(p => p._id === placement._id)?.comments || ''}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={placement.selected}
                    onChange={(e) =>
                      handlePlacementChange(placement._id, 'selected', e.target.checked)
                    }
                  />
                  <TextField
                    label="Proposed Fee"
                    type="number"
                    size="small"
                    sx={{ ml: 2, width: 120 }}
                    value={placement.proposedFee}
                    onChange={(e) =>
                      handlePlacementChange(placement._id, 'proposedFee', e.target.value)
                    }
                  />
                </Box>
                
              </Box>
            </Paper>
          ))}

          <TextField
            autoFocus
            label="Comments"
            fullWidth
            multiline
            rows={3}
            value={negotiationMessage}
            onChange={(e) => setNegotiationMessage(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApplyModal}>Cancel</Button>
          <Button variant="contained" 
          onClick={handleSubmitApplication}
          disabled={isApplicationLoading}
            >Submit Application</Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default PaidInfluencerCampaignDetailCreator;

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database'; 
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyDsqsd__548naC94S6q4uf9L3fSaL8Hr-s",
  authDomain: "ostendo-platform.firebaseapp.com",
  projectId: "ostendo-platform",
  storageBucket: "ostendo-platform.firebasestorage.app",
  messagingSenderId: "935313453688",
  appId: "1:935313453688:web:7e60f60d3b8adb96e4738d"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const db = getDatabase(app);
const storage = getStorage(app);

export { auth, googleProvider, facebookProvider , db, storage};

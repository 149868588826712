import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  CircularProgress
} from '@mui/material';
import { DateRangePicker } from '@mui/lab';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import { createCampaign, updateCampaign } from '../../../api/campaignsApi';
import { createBrand } from '../../../api/brandApi';
import { getAuth } from 'firebase/auth';
import { paidInfluencersCampaignData } from '../../../config/campaignType';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; 


const PaidInfluencersStepper = ({ brands = [], jobType, refreshBrands }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [file, setFile] = useState([]);
  const [campaignImageUrl, setCampaignImageUrl] = useState(null);
    const fileInputRef = useRef(null);
  


  // Stepper state
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Overview',
    'Target Audience',
    'Influencer Type',
    'Deliverables',
    'Exclusivity',
    'Campaign Expectations'
  ];
  const [completed, setCompleted] = useState({});
  const [campaignId, setCampaignId] = useState(null);
  const [openBudget, setOpenBudget] = useState(false);
  const [brandData, setBrandData] = useState({
    company_name: '',
    company_URL: '',
    company_about: ''
  });
  const [sliderValue, setSliderValue] = useState(0);
  const [postDateTimeline, setPostDateTimeline] = useState([null, null]);
  const [newBrandModalOpen, setNewBrandModalOpen] = useState(false);
  const storage = getStorage();
  // Initial state for all campaign fields relevant to Paid Influencers
  const [campaignData, setCampaignData] = useState(paidInfluencersCampaignData);
    

  const handleExclusiveChange = (key, option) => {
    setCampaignData(prev => ({
      ...prev,
      [key]: prev[key] === option ? '' : option
    }));
  };

  const handleFilesChange = (e) => {
    const selectedFile = Array.from(e.target.files);
    setFile(selectedFile);

  };

  // Handle file upload using Firebase Cloud Storage
  const handleUpload = async (action, files) => {
    setUploading(true);

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      console.log('Current User:', currentUser);
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
  
          
          const storagePath = `campaign_images/${campaignId}/${currentUser.uid}/${Date.now()}_${file[0].name}`;
          const storageRef = ref(storage, storagePath);
          console.log(storagePath)
       
          const metadata = {
            contentType: file[0].type, // Use the file's type property
          };
      
          const uploadTask = uploadBytesResumable(storageRef, file[0], metadata); // Pass metadata to uploadBytesResumable
                   
          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const totalBytes = snapshot.totalBytes;
                if (totalBytes === 0) {
                  setUploadProgress(0); // Or display a message
                  console.warn('Total bytes is zero, cannot calculate progress.');
                } else {
                  const progress = (snapshot.bytesTransferred / totalBytes) * 100;
                  console.log(`Upload progress for ${file.name}: ${progress}%`);
                  setUploadProgress(progress); // Update upload progress state
                }
              },
              (error) => {
                console.error(`Upload failed for ${file.name}:`, error);
                reject(error);
              },
              () => {
                // Upload completed, get the download URL
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    setCampaignData(prev => ({
                      ...prev,
                      imageUrl: downloadURL
                    }));
                    console.log('File available at', downloadURL);
                    resolve();
                  })
                  .catch(reject);
              }
            );
          });

        } catch (uploadError) {
          console.error('Error uploading file:', file.name, uploadError);

        } finally {
                  
            setUploading(false);
            setUploadProgress(0); // Reset upload progress
            setFile([]);
     
          }};


  


  // Simulated auto-save function (replace with your API call)
  const saveStep = async (step) => {
    if (!campaignId) {
      const newCampaign = await createCampaign(campaignData);
      setCampaignId(newCampaign);
    } else {
      await updateCampaign(campaignId, campaignData);
    }
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  };

  // Handler for Next button
  const handleNext = async () => {
    setIsLoading(true);   // (Optional) add validation here before saving
    const saved = await saveStep(activeStep);
    if (saved) {
      setCompleted(prev => ({ ...prev, [activeStep]: true }));
      if (activeStep === steps.length - 1) {
        navigate(`/campaigns/detail/${campaignId}`);
        return;
      }
      setIsLoading(false);
      setActiveStep(prev => prev + 1);
    }
  };

  // Handler for Back button
  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  // Handler for slider change
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  // Handler for advertiser selection
  const handleAdvertiserChange = (e) => {
    const value = e.target.value;
    if (value === 'newBrand') {
      setNewBrandModalOpen(true);
    } else {
      setCampaignData(prev => ({ ...prev, advertiser: value }));
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setOpenBudget(checked);
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      totalBudget: 'open' ? '' : prevData.totalBudget,
    }));
  };



  // Render content for each step
  const renderStepContent = (step) => {
    switch (step) {
      case 0: // Overview
        return (
          <Box>
            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
              Overview
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="advertiser-label">Advertiser</InputLabel>
              <Select
                labelId="advertiser-label"
                label="Brand"
                value={campaignData.advertiser}
                onChange={handleAdvertiserChange}
              >
                <MenuItem value="">
                  <em>Select Brand</em>
                </MenuItem>
                <MenuItem value="newBrand">New Brand</MenuItem>
                {brands.map((brand) => (
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Product Information"
              value={campaignData.productInformation}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, productInformation: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Name"
              value={campaignData.campaignName}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, campaignName: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Summary"
              value={campaignData.campaignSummary}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, campaignSummary: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Campaign Objective"
              value={campaignData.campaignObjective}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, campaignObjective: e.target.value }))
              }
            />

            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
             Total Budget
            </Typography>
           
            <Box>
                               <FormControlLabel
                                control={
                                <Checkbox
                                sx={{mr: 1, ml: 3}}
                                name="Open Budget"
                                checked={openBudget}
                                onChange={handleCheckboxChange}
                                />
                                  }
                                label="Open Budget"
                                />
                                </Box> 
           
            <TextField
              fullWidth
              margin="normal"
              label="Total Budget"
              type="number"
              InputLabelProps={{ shrink: true }}
              value={campaignData.totalBudget}
              disabled={openBudget}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, totalBudget: e.target.value }))
              }
            />

             



            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
              Campaign Usage Term
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={campaignData.startDate}
              d
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, startDate: e.target.value }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={campaignData.endDate}
     
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, endDate: e.target.value }))
              }
            />
     
          </Box>
        );
      case 1: // Target Audience
        return (
          <Box>

             <Box sx={{ p: 2, mb: 2 }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Upload a Campaign Image
                  </Typography>
                 
                  <input
                    type="file"
                    ref={fileInputRef}
                                     
                    onChange={handleFilesChange}
                    disabled={uploading}
                  />
                  <Button
                    variant="contained"
                    onClick={handleUpload}
                    disabled={file.length === 0 || uploading}
                    sx={{ position: 'relative' }} // Add position relative
                  >
                    {uploading ? (
                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" value={uploadProgress} size={24} />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="caption" component="div" color="text.secondary">
                            {`${Math.round(uploadProgress)}%`}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      'Upload Image'
                    )}
                  </Button>
                </Box>


                     <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
                       Age Range
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
                         const isSelected =
                           campaignData.targetAudienceAge &&
                           campaignData.targetAudienceAge.includes(age);
                         return (
                           <Chip
                             key={age}
                             label={age}
                             clickable
                             onClick={() => {
                               const current =
                                 campaignData.targetAudienceAge || [];
                               const newSelection = isSelected
                                 ? current.filter((a) => a !== age)
                                 : [...current, age];
                               setCampaignData({
                                 ...campaignData,
                                 targetAudienceAge: newSelection,
                               });
                             }}
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   
                     <Typography variant="subtitle1" marginTop={2} fontWeight="bold">
                       Gender
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {['Female', 'Male', 'Non-binary'].map((gender) => {
                         const isSelected =
                           campaignData.targetAudienceGender &&
                           campaignData.targetAudienceGender.includes(gender);
                         return (
                           <Chip
                             key={gender}
                             label={gender}
                             clickable
                             onClick={() => {
                               const current =
                                 campaignData.targetAudienceGender || [];
                               const newSelection = isSelected
                                 ? current.filter((g) => g !== gender)
                                 : [...current, gender];
                               setCampaignData({
                                 ...campaignData,
                                 targetAudienceGender: newSelection,
                               });
                             }}
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   
                     <Typography variant="subtitle1" marginTop={2}>
                       <strong>Audience Primary Location</strong> - choose one
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {[
                         'United States',
                         'China',
                         'India',
                         'United Kingdom',
                         'Germany',
                         'France',
                         'Canada',
                         'Australia',
                         'Italy',
                         'Brazil',
                       ].map((locA) => {
                         const isSelected =
                           campaignData.targetPrimaryAudienceLocation === locA;
                         return (
                           <Chip
                             key={locA}
                             label={locA}
                             clickable
                             onClick={() =>
                               setCampaignData({
                                 ...campaignData,
                                 targetPrimaryAudienceLocation:
                                   isSelected ? '' : locA,
                               })
                             }
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   
                     <Typography variant="subtitle1" marginTop={2}>
                       <strong>Audience Secondary Location</strong> - can choose multiple
                     </Typography>
                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                       {[
                         'United States',
                         'China',
                         'India',
                         'United Kingdom',
                         'Germany',
                         'France',
                         'Canada',
                         'Australia',
                         'Italy',
                         'Brazil',
                       ].map((locB) => {
                         const isSelected =
                           campaignData.targetSecondaryAudienceLocation &&
                           campaignData.targetSecondaryAudienceLocation.includes(locB);
                         return (
                           <Chip
                             key={locB}
                             label={locB}
                             clickable
                             onClick={() => {
                               const current =
                                 campaignData.targetSecondaryAudienceLocation || [];
                               const newSelection = isSelected
                                 ? current.filter((l) => l !== locB)
                                 : [...current, locB];
                               setCampaignData({
                                 ...campaignData,
                                 targetSecondaryAudienceLocation: newSelection,
                               });
                             }}
                             sx={{
                               mr: 1,
                               my: 1,
                               backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                               color: isSelected ? '#ffffff' : 'inherit',
                             }}
                           />
                         );
                       })}
                     </Box>
                   </Box>
        );
      case 2: // Influencer Type
        return (
          <Box>
     
          <Typography variant="subtitle1" fontWeight="bold">Audience Size = {sliderValue}</Typography>
         
         
         <Slider
           value={sliderValue}
           onChange={handleSliderChange}
           min={0}
           max={1000000}
           step={5000}
           marks={[
             { value: 0, label: '0' },
             { value: 1000000, label: '1M+' },
           ]}
           valueLabelDisplay="auto"
   />

<Typography variant="subtitle1" marginTop={2} fontWeight="bold">
 Influencer Age Range
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
 {['13-17', '18-24', '25-34', '35-44', '45-54', '55+'].map((age) => {
   const isSelected =
     campaignData.influencerAgeRange &&
     campaignData.influencerAgeRange.includes(age);
   return (
     <Chip
       key={age}
       label={age}
       clickable
       onClick={() => {
         const current = campaignData.influencerAgeRange || [];
         const newSelection = isSelected
           ? current.filter((a) => a !== age)
           : [...current, age];
         setCampaignData({
           ...campaignData,
           influencerAgeRange: newSelection,
         });
       }}
       sx={{
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}
</Box>

{/* Influencer Gender (Multi-Select) */}
<Typography variant="subtitle1" marginTop={2} fontWeight="bold">
 Influencer Gender
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
 {['Female', 'Male', 'Non-binary'].map((gender) => {
   const isSelected =
     campaignData.influencerGender &&
     campaignData.influencerGender.includes(gender);
   return (
     <Chip
       key={gender}
       label={gender}
       clickable
       onClick={() => {
         const current = campaignData.influencerGender || [];
         const newSelection = isSelected
           ? current.filter((g) => g !== gender)
           : [...current, gender];
         setCampaignData({
           ...campaignData,
           influencerGender: newSelection,
         });
       }}
       sx={{
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}
</Box>

{/* Influencer Location (Multi-Select) */}
<Typography variant="subtitle1" marginTop={2} fontWeight="bold">
 Influencer Location
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
 {['United States', 'China', 'India', 'United Kingdom', 'Germany', 'France', 'Canada', 'Australia', 'Italy', 'Brazil'].map((loc) => {
   const isSelected =
     campaignData.influencerLocation &&
     campaignData.influencerLocation.includes(loc);
   return (
     <Chip
       key={loc}
       label={loc}
       clickable
       onClick={() => {
         const current = campaignData.influencerLocation || [];
         const newSelection = isSelected
           ? current.filter((l) => l !== loc)
           : [...current, loc];
         setCampaignData({
           ...campaignData,
           influencerLocation: newSelection,
         });
       }}
       sx={{
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}

</Box>

{/* Influencer Category / Niche (Multi-Select) */}
<Typography variant="subtitle1" marginTop={2}>
 Influencer Category / Niche
</Typography>
<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
 {[
   'Fashion', 'Beauty', 'Tech', 'Food', 'Travel', 'Lifestyle',
   'Fitness', 'Health & Wellness', 'Gaming', 'Music', 'Comedy', 'DIY & Crafts',
   'Home Decor', 'Parenting', 'Photography', 'Art', 'Design', 'Finance',
   'Business', 'Marketing', 'Education', 'Sports', 'Automotive', 'Nature',
   'Pets', 'Sustainability', 'Environmental', 'Science', 'Books', 'Film',
   'Entertainment', 'Luxury', 'Travel & Adventure', 'Food & Beverage', 'Cooking',
   'Gardening', 'Architecture', 'Relationships', 'Spirituality', 'Mental Health',
   'Animation', 'Comedy & Memes', 'DIY Home Improvement', 'Personal Development',
   'Productivity', 'Photography & Videography', 'Social Media Tips', 'Cryptocurrency',
   'E-Sports', 'Virtual Reality'
 ].map((cat) => {
   const isSelected =
     campaignData.influencerCategory &&
     campaignData.influencerCategory.includes(cat);
   return (
     <Chip
       key={cat}
       label={cat}
       clickable
       onClick={() => {
         const current = campaignData.influencerCategory || [];
         const newSelection = isSelected
           ? current.filter((c) => c !== cat)
           : [...current, cat];
         setCampaignData({
           ...campaignData,
           influencerCategory: newSelection,
         });
       }}
       sx={{
         flex: '0 0 calc(100%/10 - 8px)',
         mr: 1,
         my: 1,
         backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
         color: isSelected ? '#ffffff' : 'inherit',
       }}
     />
   );
 })}
</Box></Box>
        );
      case 3: // Deliverables
        return (
          <Box>
           <Typography variant="subtitle1" fontWeight="bold">
                               Add Placements
                             </Typography>
                             {campaignData.placements.map((placement, index) => (
                               <Box
                                 key={index}
                                 sx={{
                                   border: '1px solid #ccc',
                                   padding: 2,
                                   marginBottom: 2,
                                 }}
                               >
                                 <FormControl fullWidth margin="normal">
                                   <InputLabel id={`platform-label-${index}`}>
                                     Platform
                                   </InputLabel>
                                   <Select
                                     labelId={`platform-label-${index}`}
                                     label="Platform"
                                     value={placement.platform || ''}
                                     onChange={(e) => {
                                       const newPlacements = [...campaignData.placements];
                                       newPlacements[index].platform = e.target.value;
                                       // Reset placement type if platform changes
                                       newPlacements[index].placementType = '';
                                       setCampaignData({
                                         ...campaignData,
                                         placements: newPlacements,
                                       });
                                     }}
                                   >
                                     <MenuItem value="">
                                       <em>Select Platform</em>
                                     </MenuItem>
                                     {['Instagram', 'TikTok', 'Youtube', 'Facebook', 'Snap'].map(
                                       (platform) => (
                                         <MenuItem key={platform} value={platform}>
                                           {platform}
                                         </MenuItem>
                                       )
                                     )}
                                   </Select>
                                 </FormControl>
                                 <FormControl fullWidth margin="normal">
                                   <InputLabel id={`placement-type-label-${index}`}>
                                     Placement Type
                                   </InputLabel>
                                   <Select
                                     labelId={`placement-type-label-${index}`}
                                     label="Placement Type"
                                     value={placement.placementType || ''}
                                     onChange={(e) => {
                                       const newPlacements = [...campaignData.placements];
                                       newPlacements[index].placementType = e.target.value;
                                       setCampaignData({
                                         ...campaignData,
                                         placements: newPlacements,
                                       });
                                     }}
                                   >
                                     <MenuItem value="">
                                       <em>Select Placement Type</em>
                                     </MenuItem>
                                     {placement.platform === 'Instagram'
                                       ? [
                                           <MenuItem key="InstagramFeed" value="Feed Placement">
                                             Feed Placement
                                           </MenuItem>,
                                           <MenuItem
                                             key="InstagramSingleImage"
                                             value="Single Image Post"
                                           >
                                             Single Image Post
                                           </MenuItem>,
                                           <MenuItem key="InstagramCarousel" value="Carousel Post">
                                             Carousel Post
                                           </MenuItem>,
                                           <MenuItem key="InstagramVideo" value="Video Post">
                                             Video Post
                                           </MenuItem>,
                                           <MenuItem key="InstagramReels" value="Reels">
                                             Reels
                                           </MenuItem>,
                                           <MenuItem key="InstagramStories" value="Stories">
                                             Stories
                                           </MenuItem>,
                                         ]
                                       : null}
                                     {placement.platform === 'TikTok'
                                       ? [
                                           <MenuItem key="TikTokFeed" value="Feed Placement">
                                             Feed Placement
                                           </MenuItem>,
                                           <MenuItem key="TikTokVideo" value="Video Post">
                                             Video Post
                                           </MenuItem>,
                                           <MenuItem key="TikTokStitch" value="Stitch">
                                             Stitch
                                           </MenuItem>,
                                           <MenuItem key="TikTokDuet" value="Duet">
                                             Duet
                                           </MenuItem>,
                                           <MenuItem key="TikTokStories" value="Stories">
                                             Stories
                                           </MenuItem>,
                                         ]
                                       : null}
                                     {placement.platform === 'Youtube'
                                       ? [
                                           <MenuItem key="YoutubeLong" value="Long form">
                                             Long form
                                           </MenuItem>,
                                           <MenuItem key="YoutubeShort" value="Short">
                                             Short
                                           </MenuItem>,
                                           <MenuItem key="YoutubeLive" value="Live">
                                             Live
                                           </MenuItem>,
                                           <MenuItem key="YoutubeStories" value="Stories">
                                             Stories
                                           </MenuItem>,
                                         ]
                                       : null}
                                     {placement.platform === 'Facebook'
                                       ? [
                                           <MenuItem key="FacebookFeed" value="Feed Placement">
                                             Feed Placement
                                           </MenuItem>,
                                           <MenuItem
                                             key="FacebookSingle"
                                             value="Single Image Post"
                                           >
                                             Single Image Post
                                           </MenuItem>,
                                           <MenuItem
                                             key="FacebookCarousel"
                                             value="Carousel Post"
                                           >
                                             Carousel Post
                                           </MenuItem>,
                                           <MenuItem key="FacebookVideo" value="Video Post">
                                             Video Post
                                           </MenuItem>,
                                           <MenuItem key="FacebookReels" value="Reels">
                                             Reels
                                           </MenuItem>,
                                           <MenuItem key="FacebookStories" value="Stories">
                                             Stories
                                           </MenuItem>,
                                         ]
                                       : null}
                                     {placement.platform === 'Snap'
                                       ? [
                                           <MenuItem key="SnapFeed" value="Feed Placement">
                                             Feed Placement
                                           </MenuItem>,
                                           <MenuItem
                                             key="SnapSingle"
                                             value="Single Image Post"
                                           >
                                             Single Image Post
                                           </MenuItem>,
                                           <MenuItem
                                             key="SnapCarousel"
                                             value="Carousel Post"
                                           >
                                             Carousel Post
                                           </MenuItem>,
                                           <MenuItem key="SnapVideo" value="Video Post">
                                             Video Post
                                           </MenuItem>,
                                           <MenuItem key="SnapStories" value="Stories">
                                             Stories
                                           </MenuItem>,
                                         ]
                                       : null}
                                   </Select>
                                 </FormControl>
                                 <TextField
                                   fullWidth
                                   margin="normal"
                                   label="Comments"
                                   value={placement.comments || ''}
                                   onChange={(e) => {
                                     const newPlacements = [...campaignData.placements];
                                     newPlacements[index].comments = e.target.value;
                                     setCampaignData({
                                       ...campaignData,
                                       placements: newPlacements,
                                     });
                                   }}
                                 />
                   
                                 {/* Placement Objective using Chips */}
                                 <Typography variant="subtitle1">
                                   Placement Objective
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Awareness', 'Engagement', 'Traffic', 'Leads', 'Sales'].map(
                                     (obj) => {
                                       const isSelected =
                                         campaignData.placementObjective &&
                                         campaignData.placementObjective.includes(obj);
                                       return (
                                         <Chip
                                           key={obj}
                                           label={obj}
                                           clickable
                                           onClick={() => {
                                             const current =
                                               campaignData.placementObjective || [];
                                             const newSelection = isSelected
                                               ? current.filter((val) => val !== obj)
                                               : [...current, obj];
                                             setCampaignData({
                                               ...campaignData,
                                               placementObjective: newSelection,
                                             });
                                           }}
                                           sx={{
                                             mr: 1,
                                             my: 1,
                                             backgroundColor: isSelected
                                               ? '#11ba82'
                                               : '#c7c7c7',
                                             color: isSelected ? '#ffffff' : 'inherit',
                                           }}
                                         />
                                       );
                                     }
                                   )}
                                 </Box>
                   
                                 <TextField
                                   fullWidth
                                   margin="normal"
                                   label="Post Date Timeline"
                                   type="date"
                                   InputLabelProps={{ shrink: true }}
                                   value={campaignData.postDateTimeline || ''}
                                   onChange={(e) =>
                                     setCampaignData({
                                       ...campaignData,
                                       postDateTimeline: e.target.value,
                                     })
                                   }
                                 />
                                 <DateRangePicker
                                   startText="From"
                                   endText="To"
                                   value={postDateTimeline}
                                   onChange={(newValue) => {
                                     setPostDateTimeline(newValue);
                                     // Optionally update campaignData as well
                                     setCampaignData((prevData) => ({
                                       ...prevData,
                                       postDateTimeline: newValue,
                                     }));
                                   }}
                                   renderInput={(startProps, endProps) => (
                                     <Box sx={{ display: 'flex', gap: 2 }}>
                                       <TextField {...startProps} fullWidth margin="normal" />
                                       <TextField {...endProps} fullWidth margin="normal" />
                                     </Box>
                                   )}
                                 />
                                 <TextField
                                   fullWidth
                                   margin="normal"
                                   label="Content Deadlines (days prior)"
                                   type="number"
                                   value={campaignData.contentDeadlines || ''}
                                   onChange={(e) =>
                                     setCampaignData({
                                       ...campaignData,
                                       contentDeadlines: e.target.value,
                                     })
                                   }
                                 />
                   
                                 {/* Content Approval Needed using Chips (Exclusive) */}
                                 <Typography variant="subtitle1">
                                   Content Approval Needed?
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Yes', 'No'].map((cont) => {
                                     const isSelected =
                                       campaignData.contentApprovalNeeded === cont;
                                     return (
                                       <Chip
                                         key={cont}
                                         label={cont}
                                         clickable
                                         onClick={() =>
                                           setCampaignData({
                                             ...campaignData,
                                             contentApprovalNeeded: cont,
                                           })
                                         }
                                         sx={{
                                           mr: 1,
                                           my: 1,
                                           backgroundColor: isSelected
                                             ? '#11ba82'
                                             : '#c7c7c7',
                                           color: isSelected ? '#ffffff' : 'inherit',
                                         }}
                                       />
                                     );
                                   })}
                                 </Box>
                   
                                 {/* Branded Content Ads */}
                                 <Typography
                                   variant="subtitle1"
                                   fontWeight="bold"
                                   marginTop="30px"
                                 >
                                   Branded Content Ads
                                 </Typography>
                   
                                 {/* Content boosted from Creator’s channels */}
                                 <Typography variant="subtitle1">
                                   Content boosted from Creator’s channels
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Yes', 'No', 'TBC'].map((boost1) => {
                                     const isSelected =
                                       campaignData.boosting &&
                                       campaignData.boosting.contentBoostedFromCreatorsChannels ===
                                         boost1;
                                     return (
                                       <Chip
                                         key={boost1}
                                         label={boost1}
                                         clickable
                                         onClick={() =>
                                           setCampaignData((prevData) => ({
                                             ...prevData,
                                             boosting: {
                                               ...prevData.boosting,
                                               contentBoostedFromCreatorsChannels: boost1,
                                             },
                                           }))
                                         }
                                         sx={{
                                           mr: 1,
                                           my: 1,
                                           backgroundColor: isSelected
                                             ? '#11ba82'
                                             : '#c7c7c7',
                                           color: isSelected ? '#ffffff' : 'inherit',
                                         }}
                                       />
                                     );
                                   })}
                                 </Box>
                   
                                 {/* Content boosted from Brand's channels */}
                                 <Typography variant="subtitle1">
                                   Content boosted from Brand's channels
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Yes', 'No', 'TBC'].map((boost2) => {
                                     const isSelected =
                                       campaignData.boosting &&
                                       campaignData.boosting.contentBoostedFromBrandsChannels ===
                                         boost2;
                                     return (
                                       <Chip
                                         key={boost2}
                                         label={boost2}
                                         clickable
                                         onClick={() =>
                                           setCampaignData((prevData) => ({
                                             ...prevData,
                                             boosting: {
                                               ...prevData.boosting,
                                               contentBoostedFromBrandsChannels: boost2,
                                             },
                                           }))
                                         }
                                         sx={{
                                           mr: 1,
                                           my: 1,
                                           backgroundColor: isSelected
                                             ? '#11ba82'
                                             : '#c7c7c7',
                                           color: isSelected ? '#ffffff' : 'inherit',
                                         }}
                                       />
                                     );
                                   })}
                                 </Box>
                   
                                 {/* Brand to License Content */}
                                 <Typography
                                   variant="subtitle1"
                                   fontWeight="bold"
                                   marginTop="30px"
                                 >
                                   Brand to License Content
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Yes', 'No'].map((license) => {
                                     const isSelected =
                                       campaignData.contentUsage &&
                                       campaignData.contentUsage.brandToLicense === license;
                                     return (
                                       <Chip
                                         key={license}
                                         label={license}
                                         clickable
                                         onClick={() =>
                                           setCampaignData((prevData) => ({
                                             ...prevData,
                                             contentUsage: {
                                               ...prevData.contentUsage,
                                               brandToLicense: license,
                                             },
                                           }))
                                         }
                                         sx={{
                                           mr: 1,
                                           my: 1,
                                           backgroundColor: isSelected
                                             ? '#11ba82'
                                             : '#c7c7c7',
                                           color: isSelected ? '#ffffff' : 'inherit',
                                         }}
                                       />
                                     );
                                   })}
                                 </Box>
                   
                                 {campaignData.contentUsage?.brandToLicense === 'Yes' && (
                                   <>
                                     <Typography
                                       variant="subtitle1"
                                       fontWeight="bold"
                                       marginTop="30px"
                                     >
                                       Usage Permissions
                                     </Typography>
                                     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                       {[
                                         'Social Media',
                                         'Open Web',
                                         'DOOH',
                                         'OOH',
                                         'TV',
                                         'BVOD',
                                         'Brand Website',
                                       ].map((usage) => {
                                         const isSelected =
                                           campaignData.contentUsage?.permissions?.usageRights?.includes(
                                             usage
                                           );
                                         return (
                                           <Chip
                                             key={usage}
                                             label={usage}
                                             clickable
                                             onClick={() => {
                                               const current =
                                                 (campaignData.contentUsage?.permissions?.usageRights) ||
                                                 [];
                                               const newSelection = isSelected
                                                 ? current.filter((u) => u !== usage)
                                                 : [...current, usage];
                                               setCampaignData((prevData) => ({
                                                 ...prevData,
                                                 contentUsage: {
                                                   ...prevData.contentUsage,
                                                   permissions: {
                                                     ...prevData.contentUsage.permissions,
                                                     usageRights: newSelection,
                                                   },
                                                 },
                                               }));
                                             }}
                                             sx={{
                                               mr: 1,
                                               my: 1,
                                               backgroundColor: isSelected
                                                 ? '#11ba82'
                                                 : '#c7c7c7',
                                               color: isSelected ? '#ffffff' : 'inherit',
                                             }}
                                           />
                                         );
                                       })}
                                     </Box>
                                   </>
                                 )} 
                   
                                 <Typography
                                   variant="subtitle1"
                                   fontWeight="bold"
                                   marginTop="30px"
                                 >
                                   Brand Permissions
                                 </Typography>
                                 <Typography variant="subtitle1">
                                   Reshare content on Brand Socials
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Yes', 'No'].map((reshare1) => {
                                     const isSelected =
                                       campaignData.resharing?.onBrandSocials === reshare1;
                                     return (
                                       <Chip
                                         key={reshare1}
                                         label={reshare1}
                                         clickable
                                         onClick={() =>
                                           setCampaignData((prevData) => ({
                                             ...prevData,
                                             resharing: {
                                               ...prevData.resharing,
                                               onBrandSocials: reshare1,
                                             },
                                           }))
                                         }
                                         sx={{
                                           mr: 1,
                                           my: 1,
                                           backgroundColor: isSelected
                                             ? '#11ba82'
                                             : '#c7c7c7',
                                           color: isSelected ? '#ffffff' : 'inherit',
                                         }}
                                       />
                                     );
                                   })}
                                 </Box>
                                 <Typography variant="subtitle1">
                                   Repurposing - brand can make edits
                                 </Typography>
                                 <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                   {['Yes', 'No'].map((editable) => {
                                     const isSelected =
                                       campaignData.resharing?.editable === editable;
                                     return (
                                       <Chip
                                         key={editable}
                                         label={editable}
                                         clickable
                                         onClick={() =>
                                           setCampaignData((prevData) => ({
                                             ...prevData,
                                             resharing: {
                                               ...prevData.resharing,
                                               editable: editable,
                                             },
                                           }))
                                         }
                                         sx={{
                                           mr: 1,
                                           my: 1,
                                           backgroundColor: isSelected
                                             ? '#11ba82'
                                             : '#c7c7c7',
                                           color: isSelected ? '#ffffff' : 'inherit',
                                         }}
                                       />
                                     );
                                   })}
                                 </Box>
           
                                 <Typography
                                   variant="subtitle1"
                                   fontWeight="bold"
                                   marginTop="30px"
                                 >
                                   Remuneration
                                 </Typography>
                                 <TextField
                                   fullWidth
                                   margin="normal"
                                   label="Fee"
                                   type="number"
                                   InputLabelProps={{ shrink: true }}
                                   value={placement.fee || ''}
                                   onChange={(e) => {
                                       const newPlacements = [...campaignData.placements];
                                       newPlacements[index] = {
                                         ...newPlacements[index],
                                         fee: e.target.value,
                                       };
                                       setCampaignData({
                                         ...campaignData,
                                         placements: newPlacements,
                                       });
                                     }}
                                   />
                                    <TextField
                                   fullWidth
                                   margin="normal"
                                   label="Product value"
                                   type="number"
                                   InputLabelProps={{ shrink: true }}
                                   value={placement.productValue || ''}
                                   onChange={(e) => {
                                       const newPlacements = [...campaignData.placements];
                                       newPlacements[index] = {
                                         ...newPlacements[index],
                                         productValue: e.target.value,
                                       };
                                       setCampaignData({
                                         ...campaignData,
                                         placements: newPlacements,
                                       });
                                     }}
                                   />
                   
                                 <Box mt={2}>
                                   <Button
                                     variant="outlined"
                                     color="error"
                                     onClick={() => {
                                       const newPlacements = [...campaignData.placements];
                                       newPlacements.splice(index, 1);
                                       setCampaignData({
                                         ...campaignData,
                                         placements: newPlacements,
                                       });
                                     }}
                                   >
                                     Remove Placement
                                   </Button>
                                 </Box>
                               </Box>
                             ))}
                             <Button
                               variant="contained"
                               onClick={() =>
                                 setCampaignData({
                                   ...campaignData,
                                   placements: [...campaignData.placements, {}],
                                 })
                               }
                             >
                               Add Placement
                             </Button>
                       
          </Box>
        );
      case 4: 
        return (
          <Box>
            <FormControl component="fieldset" margin="normal">
              <Typography variant="subtitle1" fontWeight="bold">
                Category Exclusivity
              </Typography>
             
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Yes', 'No'].map((exlusivity) => {
      const isSelected =
        campaignData.categoryExclusivity &&
        campaignData.categoryExclusivity.includes(exlusivity);
      return (
        <Chip
        exclusive
          key={exlusivity}
          label={exlusivity}
          clickable
          onClick={() => {
            const current = campaignData.categoryExclusivity || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== exlusivity)
              : [...current, exlusivity];
            setCampaignData({
              ...campaignData,
              categoryExclusivity: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Competitor Exclusivity (list specific brands)"
              value={campaignData.competitorExclusivity || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  competitorExclusivity: e.target.value,
                })
              }
            />
            <FormControl fullWidth margin="normal">

            <Typography variant="subtitle1" fontWeight="bold">
                Territory Exclusivity
              </Typography>
             
  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
    {['Country', 'Region', 'Global'].map((exlusivityT) => {
      const isSelected =
        campaignData.territoryExclusivity &&
        campaignData.territoryExclusivity.includes(exlusivityT);
      return (
        <Chip
        exclusive
          key={exlusivityT}
          label={exlusivityT}
          clickable
          onClick={() => {
            const current = campaignData.territoryExclusivity || [];
            const newSelection = isSelected
              ? current.filter((a) => a !== exlusivityT)
              : [...current, exlusivityT];
            setCampaignData({
              ...campaignData,
              territoryExclusivity: newSelection,
            });
          }}
          sx={{
            mr: 1,
            my: 1,
            backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
            color: isSelected ? '#ffffff' : 'inherit',
          }}
        />
      );
    })}
  </Box>



            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              label="Exclusivity Period in Months"
              value={campaignData.exclusivityPeriod || ''}
              onChange={(e) =>
                setCampaignData({
                  ...campaignData,
                  exclusivityPeriod: e.target.value,
                })
              }
            />
          </Box>
        );
      case 5: // Campaign Expectations
        return (
          <Box>
            <Typography variant="subtitle1" marginTop="30px" fontWeight="bold">
              Campaign Expectations
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Content Revisions"
              type="number"
              value={campaignData.contentRevisions}
              onChange={(e) =>
                setCampaignData(prev => ({
                  ...prev,
                  contentRevisions: e.target.value
                }))
              }
            />
            <TextField
              fullWidth
              margin="normal"
              label="Performance Metrics (e.g. eCPM, CPC, CPA, etc.)"
              value={campaignData.performanceMetrics}
              onChange={(e) =>
                setCampaignData(prev => ({
                  ...prev,
                  performanceMetrics: e.target.value
                }))
              }
            />
            <Box>
              <Typography variant="subtitle1">Reporting Required</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.reportingRequired === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, reportingRequired: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">Paid Disclosure</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.paidDisclosure === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, paidDisclosure: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">Brand Tagging</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.brandTagging === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, brandTagging: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">Include Shoppable Links</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.shoppableLinks === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, shoppableLinks: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1">
                Interactivity (Polls, Surveys, Q+As)
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {['Yes', 'No'].map((option) => {
                  const isSelected = campaignData.interactivity === option;
                  return (
                    <Chip
                      key={option}
                      label={option}
                      clickable
                      onClick={() =>
                        setCampaignData(prev => ({ ...prev, interactivity: option }))
                      }
                      sx={{
                        mr: 1,
                        my: 1,
                        backgroundColor: isSelected ? '#11ba82' : '#c7c7c7',
                        color: isSelected ? '#ffffff' : 'inherit'
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            <TextField
              fullWidth
              margin="normal"
              label="Other / Final Comments"
              multiline
              rows={4}
              value={campaignData.finalComments || ''}
              onChange={(e) =>
                setCampaignData(prev => ({ ...prev, finalComments: e.target.value }))
              }
            />
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>
              {completed[index] ? <span>&#10003;</span> : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>{renderStepContent(activeStep)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button 
        variant="contained" 
        onClick={handleNext}
        disabled={isLoading}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>

      {/* New Brand Modal */}
      <Dialog open={newBrandModalOpen} onClose={() => setNewBrandModalOpen(false)}>
        <DialogTitle>Create New Brand</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the new brand details below.</DialogContentText>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            name="company_name"
            value={brandData.company_name}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_name: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
          <TextField
            label="Company URL"
            variant="outlined"
            fullWidth
            name="company_URL"
            value={brandData.company_URL}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_URL: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
          <TextField
            label="Company About"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="company_about"
            value={brandData.company_about}
            onChange={(e) =>
              setBrandData(prev => ({ ...prev, company_about: e.target.value }))
            }
            required
            sx={{ marginBottom: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewBrandModalOpen(false)}>Cancel</Button>
          <Button onClick={async () => {
            try {
              const auth = getAuth();
              const user = auth.currentUser;
              if (user) {
                const idToken = await user.getIdToken();
                await createBrand(brandData, idToken);
              }
            } catch (error) {
              console.error('Error creating brand:', error);
            } finally {
              await refreshBrands();
              setNewBrandModalOpen(false);
            }
          }}>Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PaidInfluencersStepper;

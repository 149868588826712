import React from 'react';
import {  
  Box,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Badge
     } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp,  MoreVert as MoreVertIcon} from '@mui/icons-material';
import { TASK_FLOW } from '../../../../config/tasks';
import { updateTask } from '../../../../api/tasks';
import CreatorDetailCard from '../../creators/ProfileWidget';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import {useEffect} from 'react';



const PaidInfluencersMediaPlan = ({ applications, triggerRefreshApplications }) => {
 



  
  const ApplicationRow = ({ app }) => {
    const [open, setOpen] = useState(false);
    const [openCreatorProfile, setOpenCreatorProfile] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [trackingModalOpen, setTrackingModalOpen] = useState(false);
    const [postingDatesModalOpen, setPostingDatesModalOpen] = useState(false);
    const [uploadCreativeBriefModalOpen, setUploadCreativeBriefModalOpen] = useState(false);
    const [approveCreativeConceptsModalOpen, setApproveCreativeConceptsModalOpen] = useState(false);
    const [markCampaignCompleteModalOpen, setMarkCampaignCompleteModalOpen] = useState(false);
    const [receiveCreativeModalOpen, setReceiveCreativeModalOpen] = useState(false);
    const [carrier, setCarrier] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [selectedTask, setSelectedTask] = useState(null);  
    const navigate = useNavigate();
    const [tasks, setTasks] = useState(app.tasks || []);
    const [tasksMessage, setTasksMessage] = useState('');
    const [tasksLoading, setTasksLoading] = useState(false);



    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const toggleProfile = () => {
      setOpenCreatorProfile(prev => !prev);
      console.log(openCreatorProfile)
    };

    const totalCost = app.placementsAppliedTo
    ? app.placementsAppliedTo.reduce(
        (sum, placement) =>
          sum + (Number(placement.proposedFee) || 0),
        0
      )
    : 0;

    const adminTasksOutstanding = (Number(tasks.filter(task => task.status !== 'Completed' && task.type === 'Admin').length));
    const creatorTasksOutstanding = (Number(tasks.filter(task => task.status !== 'Completed' && task.type === 'Creator').length));
    const completedTasks = (Number(tasks.filter(task => task.status === 'Completed').length));

    const adminTasks = Object.values(tasks)
    .flat()
    .filter(task => task.type === 'Admin' && task.status !== 'Completed');



    const handleTaskClick = (task) => {
      // Trigger different actions based on the selected task
      console.log(`Task for app ${app._id}: ${task.taskName}: ${task._id}`);
      if (task.taskName === 'Send Product') {
        setSelectedTask(task);

        setTrackingModalOpen(true);
       
        return;
      }
      if (task.taskName === 'Confirm Posting Dates') {
        setSelectedTask(task);
        setPostingDatesModalOpen(true);
     
        return;
      }
      if (task.taskName === 'Upload Creative Brief') {
       setSelectedTask(task);
       setUploadCreativeBriefModalOpen(true);
       return;
      }
      if (task.taskName === 'Approve Concepts') {
        setSelectedTask(task);
        setApproveCreativeConceptsModalOpen(true);
        return;
      }
      if (task.taskName === 'Receive Creative') {
        setSelectedTask(task);
        setReceiveCreativeModalOpen(true);

      }
      if (task.taskName === 'Give Creative Feedback') {
        setSelectedTask(task);

      }
      if (task.taskName === 'Approve Creative') {
        setSelectedTask(task);

      }
      if (task.taskName === 'Mark Campaign Complete') {
        setSelectedTask(task);
        setMarkCampaignCompleteModalOpen(true);
      }

      setAnchorEl(null);
    };



    const handleSubmitTrackingInfo = async () => {
      if (!selectedTask) return;
      const taskData = {
        carrier,
        trackingNumber,
      };
      setTasksLoading(true);
      await updateTask("Completed", selectedTask._id, taskData);
      setTrackingModalOpen(false);
      setTasksLoading(false);
      setCarrier('');
      setTrackingNumber('');
      setSelectedTask(null);
      triggerRefreshApplications();
      
    };

    const handleConfirmPostingDates = async () => {
      if (!selectedTask) return;
      setTasksLoading(true);
      await updateTask("Completed", selectedTask._id);
      setTrackingModalOpen(false);
      setTasksLoading(false);
      {/*  setPostingDates(''); */}
      setSelectedTask(null);
      triggerRefreshApplications();
    };

    const handleUploadCreativeBrief = async () => {
      if (!selectedTask) return;
      setTasksLoading(true);
      await updateTask("Completed", selectedTask._id);
      setUploadCreativeBriefModalOpen(false);
      setTasksLoading(false);
       {/*  handleBriefURL(''); */}
      // Optional: clear the selected task after updating
      setSelectedTask(null);
      triggerRefreshApplications();
    };

    const handleApproveCreativeConcepts = async () => {
      if (!selectedTask) return;
      setTasksLoading(true);
      await updateTask("Completed", selectedTask._id);
      setApproveCreativeConceptsModalOpen(false);
      setTasksLoading(false);
      // Optional: clear the selected task after updating
      setSelectedTask(null);
      triggerRefreshApplications();
    }

    const handleOpenCreativeReview = (creativeUrl) => {
      // Option 1: navigate to a dedicated review page, passing the creative URL in the route state
      navigate('/creative-review', { state: { creativeUrl, task: selectedTask } });
      
      // Option 2: open another modal with a detailed review of the creative
      // setSelectedCreative(creativeUrl);
      // setCreativeReviewModalOpen(true);
    };
    
    const adminsTasks = tasks.filter(task => task.type === 'Admin');
    const creatorsTasks = tasks.filter(task => task.type === 'Creator');
  
    return (

    
      <>
   
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell onClick={toggleProfile} sx={{ cursor: 'pointer' }}>
            <Avatar src={app.creatorDetails?.[0]?.profileImage} alt={app.creatorDetails?.[0]?.displayName} />
          </TableCell >
          <TableCell onClick={toggleProfile} sx={{ cursor: 'pointer' }}>{app.creatorDetails?.[0]?.displayName}</TableCell>
          <TableCell>{app.status}</TableCell>
           <TableCell>${totalCost || '-'}</TableCell>
          <TableCell>{adminTasksOutstanding}</TableCell>
          <TableCell>{creatorTasksOutstanding}</TableCell>
          <TableCell>{completedTasks}</TableCell>
     {/*     <TableCell>
          <IconButton onClick={handleMenuOpen}>
          <Badge badgeContent={adminTasks.length || 0} color="primary">
            <MoreVertIcon />
            </Badge>
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {adminTasks.map((task, index) => (
                <MenuItem key={index} onClick={() => handleTaskClick(task)}>
                 
                  {task.taskName}
                 
                </MenuItem>
              ))}
            </Menu>
       
          </TableCell> */}
        
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
  <Box margin={2} sx={{ display: 'flex', gap: 2}}>
    {/* Admin Tasks Table */}
    <Typography variant="subtitle2" gutterBottom>
      Admin Tasks
    </Typography>
    {adminsTasks.length > 0 ? (
      <Table size="small" aria-label="admin tasks">
        <TableHead>
          <TableRow>
            <TableCell><strong>Task Name</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminsTasks.map((task) => (
            <TableRow key={task._id}>
              <TableCell>{task.taskName}</TableCell>
              <TableCell>{task.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <Typography variant="body2">No admin tasks available.</Typography>
    )}

    {/* Spacer */}
    <Box my={2} />

    {/* Creator Tasks Table */}
    <Typography variant="subtitle2" gutterBottom>
      Creator Tasks
    </Typography>
    {creatorsTasks.length > 0 ? (
      <Table size="small" aria-label="creator tasks">
        <TableHead>
          <TableRow>
            <TableCell><strong>Task Name</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {creatorsTasks.map((task) => (
            <TableRow key={task._id}>
              <TableCell>{task.taskName}</TableCell>
              <TableCell>{task.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : (
      <Typography variant="body2">No creator tasks available.</Typography>
    )}
  </Box>
</Collapse>
          </TableCell>
        </TableRow>
         

{/* Creator Detail Card modal*/}

<Dialog open={openCreatorProfile} onClose={() => setOpenCreatorProfile(false)}>
            <Box margin={0}>
              {/* Render the creator's profile card */}
              <CreatorDetailCard userData={app.creatorDetails?.[0]} />
            </Box>
      
     
     
      </Dialog>

          {/* TRACKING MODAL */}
        <Dialog open={trackingModalOpen} onClose={() => setTrackingModalOpen(false)}>
          <DialogTitle>Provide Tracking Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the tracking information for the shipped product.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Carrier"
              type="text"
              fullWidth
              value={carrier}
              onChange={(e) => setCarrier(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Tracking Number"
              type="text"
              fullWidth
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTrackingModalOpen(false)}>Cancel</Button>
            <Button onClick={handleSubmitTrackingInfo}
            disabled={tasksLoading}>Submit</Button>
          </DialogActions>
        </Dialog>


          {/* POSTING DATES MODAL */}        
        <Dialog open={postingDatesModalOpen} onClose={() => setPostingDatesModalOpen(false)}>
          <DialogTitle>Posting Dates Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please confirm the desired posting dates.
            </DialogContentText>
           {/* Add your form fields here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPostingDatesModalOpen(false)}>Cancel</Button>
            <Button onClick={handleConfirmPostingDates} disabled={tasksLoading}>Submit</Button>
          </DialogActions>
        </Dialog>

              {/* UPLOAD CREATIVE BRIEF MODAL */}        
              <Dialog open={uploadCreativeBriefModalOpen} onClose={() => setUploadCreativeBriefModalOpen(false)}>
          <DialogTitle>Upload Your Creative Brief</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Must be work/doc/pdf etc.
            </DialogContentText>
           {/* Add your form fields here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUploadCreativeBriefModalOpen(false)}>Cancel</Button>
            <Button onClick={handleUploadCreativeBrief} disabled={tasksLoading}>Submit</Button>
          </DialogActions>
        </Dialog>

        
              {/* RECEIVE CREATIVE BRIEF MODAL */}        
              <Dialog
  open={receiveCreativeModalOpen}
  onClose={() => setReceiveCreativeModalOpen(false)}
  maxWidth="md"
  fullWidth
>
  <DialogTitle>Review Submitted Creatives</DialogTitle>
  <DialogContent dividers>
    {selectedTask &&
    selectedTask.taskData &&
    selectedTask.taskData.creatives ? (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {selectedTask.taskData.creatives.map((url, index) => (
          <Paper
            key={index}
            sx={{ width: 120, height: 120, p: 1, cursor: 'pointer' }}
            onClick={() => handleOpenCreativeReview(url)}
          >
            <img
              src={url}
              alt={`Creative ${index + 1}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Paper>
        ))}
      </Box>
    ) : (
      <Typography variant="body2">No creatives available.</Typography>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setReceiveCreativeModalOpen(false)}>Close</Button>
  </DialogActions>
</Dialog>

                   {/* APPROVE CREATIVE CONCEPTS */}        
                   <Dialog open={approveCreativeConceptsModalOpen} onClose={() => setApproveCreativeConceptsModalOpen(false)}>
          <DialogTitle>Review and Approve Creative Concepts</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Decide if this is ok in a modal or needs to be in a seperate page
            </DialogContentText>
           {/* Add your form fields here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setApproveCreativeConceptsModalOpen(false)}>Cancel</Button>
            <Button onClick={handleApproveCreativeConcepts} disabled={tasksLoading}>Submit</Button>
          </DialogActions>
        </Dialog>

                     {/* MARK CAMPAIGN COMPLETE / APPROVE INVOICING */}        
                     <Dialog open={markCampaignCompleteModalOpen} onClose={() => setMarkCampaignCompleteModalOpen(false)}>
          <DialogTitle>Review and Approve Creative Concepts</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Decide if this is ok in a modal or needs to be in a seperate page
            </DialogContentText>
           {/* Add your form fields here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setApproveCreativeConceptsModalOpen(false)}>Cancel</Button>
            <Button onClick={handleApproveCreativeConcepts} disabled={tasksLoading} >Submit</Button>
          </DialogActions>
        </Dialog>



      </>
    );
  };

  



  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2, mt: 5  }}>Media Plan</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="Media Plan Table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell><strong>Avatar</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Total Cost</strong></TableCell>
              <TableCell><strong>Admin Tasks</strong></TableCell>
              <TableCell><strong>Creator Tasks</strong></TableCell>
              <TableCell><strong>Completed Tasks</strong></TableCell>
   
       
            </TableRow>
          </TableHead>
          <TableBody>
            {applications
              .filter((app) => app.status === 'Accepted')
              .map((app) => (
                <ApplicationRow key={app._id} app={app} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaidInfluencersMediaPlan;
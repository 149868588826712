import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography,
  Avatar,
  Button,
  Badge
} from '@mui/material';
import { updateApplication } from '../../../api/applicationApi';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}

const CreatorApplicationsBulkTabs = ({ applicationData, triggerApplicationsFetch }) => {
  const [applications, setApplications] = useState(applicationData || []);
  const [selected, setSelected] = useState([]); // store selected application _id's
  const [currentTab, setCurrentTab] = useState(0);
  const [applicationUpdateLoading, setApplicationUpdateLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setSelected([]); // reset selection when switching tabs
  };

    
  useEffect(() => {
    setApplications(applicationData);
    console.log(applications);
  }, [applicationData]);


  const StatusDot = ({ status }) => {
    let color = 'grey';
    if (status === 'Accepted') {
      color = 'green';
    } else if (status === 'Declined') {
      color = 'red';
    } else if (status === 'Shortlisted') {
      color = 'blue';
    } else if (status === 'Pending') {
      color = 'orange';
    }
    return (
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: color,
        }}
      />
    );
  };

  // Derive the active list based on current tab
  const getActiveList = () => {
    switch (currentTab) {
      case 3:
        return applications.filter(app => app.status === 'Declined');
      case 2:
        return applications.filter(app => app.status === 'Accepted');
      case 1:
        return applications.filter(app => app.status === 'Shortlisted');
      case 0:
        return applications.filter(app => app.status === 'Pending');
      default:
        return applications;
    }
  };


  const activeList = getActiveList();

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(item => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  // Example: update application status for single-row action
  const handleUpdateApplicationStatus = async (application, status) => {
    setApplicationUpdateLoading(true);
    const applicationId = application._id;
    const jobId = application.campaignId;
  await updateApplication(applicationId, status);
  await triggerApplicationsFetch(jobId);
  setApplicationUpdateLoading(false);
  
  };

  // Bulk action: update status for selected items (example: move Pending items to Shortlisted)
  const handleBulkAction = () => {
    // For example, if we're on Pending tab, bulk shortlist them
    if (currentTab === 0) {
      setApplications(
        applications.map(app =>
          selected.includes(app._id) ? { ...app, status: 'Shortlisted' } : app
        )
      );
    }
    // You can add conditions for other tabs similarly.
    setSelected([]);
  };

  const getTotalProposedFee = (placements) => {
    return placements?.reduce((acc, p) => acc + (Number(p.proposedFee) || 0), 0) || 0;
  };

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} aria-label="Application Status Tabs">
      <Tab label="Pending" {...a11yProps(0)} />
      <Tab label="Shortlisted" {...a11yProps(1)} />
      <Tab label="Accepted" {...a11yProps(2)} />
        <Tab label="Declined" {...a11yProps(3)} />
 

     
      </Tabs>

      <Box sx={{ mt: 2, mb: 5 }}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.length === activeList.length && activeList.length > 0}
                    indeterminate={selected.length > 0 && selected.length < activeList.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelected(activeList.map(item => item._id));
                      } else {
                        setSelected([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell><strong></strong></TableCell>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Followers</strong></TableCell>
                <TableCell><strong>Forecast eCPV</strong></TableCell>
                <TableCell><strong>Forecast eCPE</strong></TableCell>
                <TableCell><strong>Proposed Fee</strong></TableCell>
                <TableCell><strong>Buying Indicator</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeList.map((application) => (
                <TableRow key={application._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.includes(application._id)}
                      onChange={() => handleSelect(application._id)}
                    />
                  </TableCell>
                  <TableCell><Avatar /></TableCell>
                  <TableCell>{application.creatorDetails[0].displayName}</TableCell>
                  <TableCell>{Number(application.creatorDetails[0].followers).toLocaleString()}</TableCell>
                  <TableCell>{`$${(getTotalProposedFee(application.placementsAppliedTo) / (Number(application.creatorDetails[0].followers) / 10)).toFixed(2)}`}</TableCell>
                  <TableCell>{`$${(getTotalProposedFee(application.placementsAppliedTo) / (Number(application.creatorDetails[0].followers) / 200)).toFixed(2)}`}</TableCell>
                  <TableCell>${getTotalProposedFee(application.placementsAppliedTo)}</TableCell>
                  <TableCell>
                    <StatusDot status={application.status} />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {(application.status !== 'Accepted' && application.status !== 'Declined') && (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          handleUpdateApplicationStatus(
                            application,
                            application.status === 'Shortlisted' ? 'Pending' : 'Shortlisted'
                                                      );
                        }}
                        disabled={applicationUpdateLoading}
                      >
                        {application.status !== 'Shortlisted' ? 'Shortlist' : 'Remove'}
                      </Button>

                      )}
                        {application.status !== 'Accepted' && (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          handleUpdateApplicationStatus(application, 'Accepted');
                        }}
                        disabled={applicationUpdateLoading}
                      >
                        Accept
                      </Button>
                        )}
                    {application.status !== 'Declined' && (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          handleUpdateApplicationStatus(application, 'Declined');
                        }}
                        disabled={applicationUpdateLoading}
                      >
                        Decline
                      </Button>
                    )}
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          // More action
                          console.log('More action', application);
                        }}
                        disabled={applicationUpdateLoading}
                      >
                       View
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>{application.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
{/*
      <Box sx={{ mt: 2, mb: 5 }}>
        <Button
          variant="contained"
          onClick={handleBulkAction}
          disabled={selected.length === 0 || applicationUpdateLoading}
        >
          Bulk Action
        </Button>
      </Box>
      */}
    </Box>
  );
};

export default CreatorApplicationsBulkTabs;

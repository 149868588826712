// RegisterPage.js
import React, { useState } from 'react';
import './RegisterPage.css';
import { registerUser } from '../../api/userApi';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';



const RegisterPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '+1',
    mobile: '',
    password: '',
    socialMedia: {
      facebook: true,
      instagram: true,
      tiktok: true,
      linkedin: true
    }
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const auth = getAuth();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const countryCodes = [
    { code: '+1', country: 'US' },
    { code: '+44', country: 'UK' },
    { code: '+33', country: 'FR' },
    { code: '+49', country: 'DE' },
    { code: '+81', country: 'JP' },
    { code: '+61', country: 'AU' }
  ];

  const handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      setFormData({
        ...formData,
        socialMedia: {
          ...formData.socialMedia,
          [e.target.name]: e.target.checked
        }
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    if (isSubmitting) return;
    setIsSubmitting(true);
    const userData = formData;
    try {
    const response = await axios.post('https://ostendo.nucleotto.com/api/creator/register',
         userData, 
         {
           headers: {
             'Content-Type': 'application/json',
           }},
        );
    if (response.status === 201) {    
    await signInWithEmailAndPassword(auth, formData.email, formData.password);
    navigate('/welcome/connect', {state: {email: formData.email}});
    }} catch (error) {
    console.error("Registration or sign-in error:", error);
    setErrorMessage("Registration failed. Please contact CrowdSwell.");
    alert("Registration failed. Please contact CrowdSwell.");
  }
};
return (
  <div className="page-wrapper">
    <div className="register-container">
      {/* Welcome Section - Now Above the Form */}
      <div className="welcome-section">
        <h1>Welcome to CrowdSwell</h1>
        <p>
          Connect with advertisers, promote the products you love and turn your
          clout into the ultimate side hustle.
        </p>
        <p>Receive. Create. Earn.</p>
      </div>

      {/* Registration Form - Below Welcome Section */}
      <div className="register-form">
        <div className="form-content">
          <h1>Register as a Creator</h1>
         

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter your first name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter your last name"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <div className="phone-input">
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  className="country-code"
                >
                  {countryCodes.map((option) => (
                    <option key={option.code} value={option.code}>
                      {option.code} ({option.country})
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="123-456-7890"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <TextField
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleChange}
                placeholder="Choose a password"
                required
                variant="outlined" // Explicitly set to outlined
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ddd', // Match other inputs
                    },
                    '&:hover fieldset': {
                      borderColor: '#bbb',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ff5e62',
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>


            <div className="form-group">
              <label>Social Media Accounts</label>
              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="facebook"
                    checked={formData.socialMedia.facebook}
                    onChange={handleChange}
                  />
                  Facebook
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="instagram"
                    checked={formData.socialMedia.instagram}
                    onChange={handleChange}
                  />
                  Instagram
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="tiktok"
                    checked={formData.socialMedia.tiktok}
                    onChange={handleChange}
                  />
                  TikTok
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="linkedin"
                    checked={formData.socialMedia.linkedin}
                    onChange={handleChange}
                  />
                  LinkedIn
                </label>
              </div>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            <button type="submit" className="submit-btn" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Register Now"}
        </button>
          </form>

          <div className="login-link">
            <p>
              Already have an account?{' '}
              <a href="/login" className="login-link-text">Log In</a>
            </p>
          </div>
        </div>
      </div>
    </div>



    

    {/* Footer */}
    <footer>
      <div className="logo-section">
        <h3>CrowdSwell</h3>
        <div className="social-icons">
          <span>F</span>
          <span>I</span>
        </div>
      </div>
      <div className="links">
        <a href="#">About</a>
        <a href="#">Contact</a>
        <a href="#">Features</a>
        <a href="#">Terms</a>
        <a href="#">Advertisers</a>
        <a href="#">Privacy</a>
      </div>
      <div className="contact-info">
        <p>2/13-15 Wentworth Ave, Darlinghurst, Sydney 2010</p>
        <p>admin@crowdswell.com.au</p>
        <p>0299312606</p>
      </div>
      <div className="message">
        <p>We’re looking for reliable, genuine and authentic CrowdSwellers to join our community</p>
      </div>
    </footer>
  </div>
);}
export default RegisterPage;
import React, { useState, useRef } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { getAuth } from 'firebase/auth'; // For Firebase auth
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage imports

const CreatorActionSelector = ({ handleAction, applicationId, triggerApplicationRefresh, triggerFileRefresh }) => {
  const [selectedAction, setSelectedAction] = useState('');
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // New state for upload progress
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  console.log("ApplicationId:", applicationId);

  // Initialize Firebase Storage
  const storage = getStorage();

  // Handle action selection and trigger file input
  const onActionChange = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file selection
  const handleFilesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  // Handle file upload using Firebase Cloud Storage
  const handleUpload = async (action, files) => {
    setUploading(true);
    let URLs = [];

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      console.log('Current User:', currentUser);
      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      for (const file of files) {
        try {
          
          // Create a storage reference with a unique path (e.g., user ID + timestamp + filename)
          const storagePath = `application_files/${applicationId}/${currentUser.uid}/${action.value}__${Date.now()}_${file.name}`;
          const storageRef = ref(storage, storagePath);

          // Upload file with resumable upload
          const uploadTask = uploadBytesResumable(storageRef, file);

          // Wait for the upload to complete and get the download URL
          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                // Optional: You could add progress tracking here if desired
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload progress for ${file.name}: ${progress}%`);
                setUploadProgress(progress); // Update upload progress state
              },
              (error) => {
                console.error(`Upload failed for ${file.name}:`, error);
                reject(error);
              },
              () => {
                // Upload completed, get the download URL
                getDownloadURL(uploadTask.snapshot.ref)
                  .then((downloadURL) => {
                    URLs.push(downloadURL);
                    resolve();
                  })
                  .catch(reject);
              }
            );
          });

        } catch (uploadError) {
          console.error('Error uploading file:', file.name, uploadError);
          // Continue to the next file even if one fails
          continue;
        }
      }

      // After successful uploads, update the task with the URLs
      await updateUploadURL(applicationId, URLs);
      triggerApplicationRefresh();
    } catch (error) {
      console.error('Error in handleUpload:', error);
    } finally {
      setUploading(false);
      setUploadProgress(0); // Reset upload progress
      setFiles([]);
      setSelectedAction('');
      triggerFileRefresh();
    }
  };

  // Update task with uploaded URLs (unchanged from your original)
  const updateUploadURL = async (applicationId, filesData) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    const authToken = await currentUser.getIdToken();
    try {
      const response = await fetch(
        '/api/application/files',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            applicationId,
            filesData,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update task: ' + (await response.text()));
      }

      console.log('Task updated:', await response.json());
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  };

  // Action options (unchanged)
  const actions = [
    { label: 'Creative Concepts', value: 'creativeConcepts' },
    { label: 'Creative For Review', value: 'creativeForReview' },
    { label: 'Campaign Report', value: 'campaignReport' },
  ];

  // Handle form submission (unchanged)
  const onSubmitAction = () => {
    if (!selectedAction) {
      console.error("No action selected.");
      return;
    }
    if (files.length === 0) {
      console.error("No files selected.");
      return;
    }
    handleUpload(
      actions.find((a) => a.value === selectedAction),
      files
    );
  };

  return (
    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Upload a File
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="admin-action-select-label">File Type</InputLabel>
        <Select
          labelId="admin-action-select-label"
          value={selectedAction}
          label="Select Action"
          onChange={onActionChange}
          disabled={uploading}
        >
          {actions.map((action, index) => (
            <MenuItem key={index} value={action.value}>
              {action.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        multiple
        style={{ display: 'none' }}
        onChange={handleFilesChange}
        disabled={uploading}
      />
      <Button
        variant="contained"
        onClick={onSubmitAction}
        disabled={!selectedAction || files.length === 0 || uploading}
        sx={{ position: 'relative' }} // Add position relative
      >
        {uploading ? (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={uploadProgress} size={24} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(uploadProgress)}%`}
              </Typography>
            </Box>
          </Box>
        ) : (
          'Upload File'
        )}
      </Button>
    </Box>
  );
};

export default CreatorActionSelector;
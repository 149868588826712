import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Divider,
  Grid2,
  Typography
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { postMessage, getCampaignMessages, getUserMessages } from '../../../../api/messaging'; // Assuming you have a getCampaignMessages API
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const ChatPanelAdmin = ({ campaignId, applications, setSelectedApplication }) => { // Now takes campaignId as a prop
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState({}); // { applicationId: [messages] }
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [sending, setSending] = useState(false);
  const chatWindowRef = useRef(null);
  const messageInputRef = useRef(null);
  const [applicationDetails, setApplicationDetails] = useState({});


useEffect(() => {

  const fetchMessages = async () => {
    try {
      const data = await getCampaignMessages(campaignId); // API call to get all messages for campaignId
      if (data) {
        setMessages(data);
      }
    } catch (error) {
      console.error("Error fetching campaign messages:", error);
    }
  };

  fetchMessages();
}, [campaignId]);





  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessages(campaignId);
    }, 5000); 

      const fetchMessages = async () => {
      try {
        const data = await getCampaignMessages(campaignId); // API call to get all messages for campaignId
        if (data) {
          setMessages(data);
        }
      } catch (error) {
        console.error("Error fetching campaign messages:", error);
      }
    };

    return () => clearInterval(interval);

  }, [campaignId]);
  
  // 1. Fetch all messages for the campaign

  

  useEffect(() => {
    // Group messages and fetch application details
    const groupMessages = () => {
      const grouped = {};
      const appDetails = {};

      messages.forEach(msg => {
        if (!grouped[msg.applicationId]) {
          grouped[msg.applicationId] = [];
        }
        grouped[msg.applicationId].push(msg);
      });

      // Fetch application details for each applicationId
      Object.keys(grouped).forEach(applicationId => {
        const application = applications.find(app => app._id === applicationId);
        if (application) {
          appDetails[applicationId] = application.creatorDetails[0].displayName;
        } else {
          appDetails[applicationId] = 'Unknown Creator';
        }
      });

      setConversations(grouped);
      setApplicationDetails(appDetails);
    };

    if (applications && messages.length > 0) {
      groupMessages();
    }
  }, [messages, applications]);



  // 3. Handle sending a new message
  const handleSendMessage = async () => {
    setSending(true);
    if (!newMessage.trim() || !selectedApplicationId) {
      setSending(false);
      return;
    }

    const messageData = {
      applicationId: selectedApplicationId,
      campaignId: campaignId,
      text: newMessage,
      from: "Admin", // Or get from user context
      to: "Creator"
    };

    try {
      await postMessage(messageData);
      // Optimistically update the UI
      setMessages(prevMessages => [...prevMessages, { ...messageData, lastUpdated: new Date(), _id: Date.now(), from: "Admin" }]); // Simulate new message
      setNewMessage('');
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      toast.success('Message sent successfully!', {
        position: 'top-right',
        autoClose: 3000, // Close after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setSending(false);
    }
  };

  // 4. Select a conversation
  const handleSelectConversation = (applicationId) => {
    setSelectedApplicationId(applicationId);
    setSelectedApplication(applicationId);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = String(date.getFullYear()).slice(2); // Get last two digits of year
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    // Scroll to bottom after messages load or update
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]); // Scroll when messages change

  return (
    <Grid2 container spacing={2} sx={{ height: '100%' }}>
      {/* Conversation List */}
      <Grid2 item xs={4} sx={{ width: '28%', borderRight: '1px solid #ccc', height: '100%', overflowY: 'auto' }}>
        <List>
          {Object.keys(conversations).map(applicationId => (
            <React.Fragment key={applicationId}>
              <ListItem
                button
                selected={selectedApplicationId === applicationId}
                onClick={() => handleSelectConversation(applicationId)}
                alignItems="flex-start"
              >
                <ListItemText
                     primary={applicationDetails[applicationId] || `${applicationId}`}
                  secondary={
                    <React.Fragment>
                      {/* Check if text is a string before using substring */}
                      {typeof conversations[applicationId][conversations[applicationId].length - 1]?.text === 'string'
                        ? conversations[applicationId][conversations[applicationId].length - 1].text.substring(0, 40) + '...'
                        : 'No message preview available'}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider sx={{mr:2}}/>
            </React.Fragment>
          ))}
        </List>
      </Grid2>

      {/* Chat Window */}
      <Grid2 item xs={8} sx={{ width: '66%', height: 'calc(100% - 68px)', maxHeight: '48vh', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <Box ref={chatWindowRef} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', p: 0, mb: 10 }}>
          {selectedApplicationId ? (
            conversations[selectedApplicationId]?.map(msg => ( 
             <Paper
                key={msg._id}
                sx={{
                  p: 1,
                  mb: 2,
                  backgroundColor: msg.from === 'Admin' ? 'lightblue' : 'lightgreen',
                  maxWidth: '60%',
                  wordBreak: 'break-word',
                  alignSelf: msg.from === 'Admin' ? 'flex-end' : 'flex-start',
                }}
              >
                <ListItemText
                 primary={msg.text}
                />  
                <Typography variant="caption" align="right" sx={{ fontStyle: 'italic', color: 'grey' }}>
                {formatDate(msg.lastUpdated)}</Typography>
              </Paper>
            
      
            ))
          ) : (
            <Typography variant="subtitle1">Select a conversation to view messages.</Typography>
          )}
        </Box>

        {/* Message Input */}
        <Box  
        ref={messageInputRef}
        sx={{  
          display: 'flex',
          alignItems: 'center',        
          borderTop: '1px solid #ccc',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',  }}>
          <TextField
            fullWidth
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
          />
          <IconButton color="primary" onClick={handleSendMessage} disabled={sending || !newMessage.trim() || !selectedApplicationId}>
            {sending ? (
              <CircularProgress size={24} />
            ) : (
              <SendIcon />
            )}
          </IconButton>
          <ToastContainer />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default ChatPanelAdmin;
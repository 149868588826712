// CalendarPanel.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

// Sample events data. In your real app, you'll likely fetch events/tasks from your API.
const sampleEvents = [
  {
    title: 'Task: Upload Creative Brief',
    start: new Date(),
    end: new Date(moment().add(1, 'hour').toDate()),
    allDay: false,
  },
  {
    title: 'Task: Confirm Posting Dates',
    start: new Date(moment().add(2, 'hour').toDate()),
    end: new Date(moment().add(3, 'hour').toDate()),
    allDay: false,
  },
  {
    title: 'Task: Mark Campaign Complete',
    start: new Date(moment().add(1, 'day').toDate()),
    end: new Date(moment().add(1, 'day').add(2, 'hour').toDate()),
    allDay: false,
  }
];

const CalendarPanel = ({ events = sampleEvents, applications }) => {


  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Calendar & Reminders
      </Typography>
      <Box sx={{ height: '40vh' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
        />
      </Box>
    </Box>
  );
};

export default CalendarPanel;

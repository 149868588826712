import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import './SocialConnectPage.css';
import { CircularProgress } from '@mui/material';

const SocialConnectPage = () => {
  const [isMetaConnecting, setIsMetaConnecting] = useState(false);
  const [isTikTokConnecting, setIsTikTokConnecting] = useState(false);
  const [isMetaConnected, setIsMetaConnected] = useState(false);
  const [isCheckingMetaConnection, setIsCheckingMetaConnection] = useState(false);
  const [userName, setUserName] = useState('');
  const [pagesData, setPagesData] = useState([]);
  const [metaAccessToken, setMetaAccessToken] = useState(null);
  const [connectedMessage, setConnectedMessage] = useState('Meta accounts connected successfully! It may take a few hours for us to retrieve the appropriate data from Meta.');
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [authError, setAuthError] = useState(null);
  const code = urlParams.get('code');
  console.log(code);
  const error = urlParams.get('error');
  const errorReason = urlParams.get('error_reason');
  const errorDescription = urlParams.get('error_description');
  const linkUrl = `/welcome/connect?code=${code || ''}&error=${error || ''}&errorReason=${errorReason || ''}&errorDescription=${errorDescription || ''}`;
  
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && code) {
        console.log('User loaded. Initiating code exchange:', code);
        setIsMetaConnecting(true);
        handleCodeExchange(code);
      } else if (!user && code) {
        console.warn('User not loaded yet.');
      }
    });
    return () => unsubscribe();
  }, [auth, code]);

  const fetchMetaData = async () => {
    console.log("fetching Meta data");
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const backendUrl = 'https://ostendo.nucleotto.com';

        const userResponse = await fetch(`${backendUrl}/api/creator/meta/user?userId=${user.uid}`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
        });
        if (!userResponse.ok) throw new Error('Failed to fetch user data');
        const userData = await userResponse.json();
        setUserName(userData.name);

        const pagesResponse = await fetch(`${backendUrl}/api/creator/meta/pages`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
        });
        if (!pagesResponse.ok) throw new Error('Failed to fetch pages data');
        const pagesData = await pagesResponse.json();
        setPagesData(pagesData);

        setAuthError(null);
        return true;
      }
    } catch (error) {
      console.error('Error fetching Meta data:', error);
      setAuthError('Failed to fetch Meta data');
      return false;
    }
  };

  const handleMetaConnect = async () => {
    console.log("connecting to Meta started");
    try {
      setIsMetaConnecting(true);
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const backendUrl = 'https://ostendo.nucleotto.com';
        const response = await axios.get(
          `${backendUrl}/api/creator/connect/meta?userId=${user.uid}`,
          { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
        );
        if (response.status === 200) {
          const authUrl = response.data.authUrl;
          console.log("Auth URL", authUrl);
          window.location.href=authUrl
        }
      }
    } catch (error) {
      console.error('Error initiating Meta connection:', error);
      alert('Failed to initiate Meta connection.');
    }
  };

  const handleCodeExchange = async (code) => {
    try {
      console.log("Exchanging code for access token");
      const auth = getAuth();
      const user = auth.currentUser;
      console.log(user);
      if (user) {
        console.log("is User");
        const idToken = await user.getIdToken();
        const backendUrl = 'https://ostendo.nucleotto.com';
        const response = await axios.post(`${backendUrl}/api/creator/connect/meta-exchange?userId=${user.uid}`, { code }, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${idToken}` },
        });
        if (response.status === 202) {
          setIsMetaConnected(true);
          // await fetchMetaData();
          // alert('Meta accounts connected successfully!');
        } else {
          throw new Error(response.data.error || 'Failed to connect Meta account');
        }
      }
    } catch (error) {
      console.error('Error connecting Meta account:', error);
      alert('Failed to connect Meta accounts.');
    } finally {
      setIsMetaConnecting(false);


    }
  };

  const handleTikTokConnect = () => {
    setIsTikTokConnecting(true);
    console.log('Initiating TikTok authentication');
    setIsTikTokConnecting(false);
  };

  const handleNext = () => {
    navigate('/creators/profile');
  };

  return (
    <div className="page-wrapper">
      <div className="social-connect-container">
        <div className="connect-wrapper">
          <h1>Connect Your Social Media</h1>
          <p>Link your accounts to verify your creator status and access opportunities</p>

          {isCheckingMetaConnection && <p>Checking connection status...</p>}

          {!isCheckingMetaConnection && (
            <div className="connect-options">
              <div className="connect-card">
                <div className="platform-logo meta-logo">
                  <span>M</span>
                </div>
                <h2>Meta Platforms</h2>
                <p>Connect your Facebook and Instagram accounts</p>
                <button
                  className="connect-btn meta-btn"
                  onClick={handleMetaConnect}
                  disabled={isMetaConnecting || isMetaConnected}
                >
                  {isMetaConnecting ? 'Connecting...' : isMetaConnected ? 'Connected' : 'Connect Meta'}
                </button>
              </div>

              <div className="connect-card">
                <div className="platform-logo tiktok-logo">
                  <span>T</span>
                </div>
                <h2>TikTok</h2>
                <p>Connect your TikTok account</p>
                <button
                  className="connect-btn tiktok-btn"
                  onClick={handleTikTokConnect}
                  disabled={isTikTokConnecting}
                  style={{ marginTop: '20px' }}
                >
                  {isTikTokConnecting ? 'Connecting...' : 'Coming Soon'}
                </button>
              </div>
            </div>
          )}

          {userName && (
            <p className="welcome-message">
              Welcome, {userName}! Your Meta account is connected.
            </p>
          )}

          {error && <p className="error-message">Error: {error}</p>}

          {isMetaConnected && (
            <p className="connected-message">{connectedMessage}</p>
          )}

          {pagesData.length > 0 && (
            <div className="pages-section">
              <h2>Your Pages</h2>
              <ul className="pages-list">
                {pagesData.map((page) => (
                  <li key={page.id} className="page-item">
                    <h3>{page.name}</h3>
                    <p>Followers: {page.followerCount}</p>
                    <p>Engagement: {page.engagement} interactions</p>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {isMetaConnected && (
            <div className="next-button-wrapper">
              <button className="connect-btn next-btn" onClick={handleNext}>
                Next
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Footer */}
      <footer>
        <div className="logo-section">
          <h3>CrowdSwell</h3>
          <div className="social-icons">
            <span>F</span>
            <span>I</span>
          </div>
        </div>
        <div className="links">
          <a href="#">About</a>
          <a href="#">Contact</a>
          <a href="#">Features</a>
          <a href="#">Terms</a>
          <a href="#">Advertisers</a>
          <a href="#">Privacy</a>
        </div>
        <div className="contact-info">
          <p>2/13-15 Wentworth Ave, Darlinghurst, Sydney 2010</p>
          <p>admin@crowdswell.com.au</p>
          <p>0299312606</p>
        </div>
        <div className="message">
          <p>We’re looking for reliable, genuine and authentic CrowdSwellers to join our community</p>
        </div>
      </footer>
    </div>
  );
};

export default SocialConnectPage;
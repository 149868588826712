// PaidInfluencersDashboard.jsx
import React, {useEffect, useState} from 'react';
import { Box, Container, Typography } from '@mui/material';
import CampaignSummaryCards from './CampaignSummaryCards';
import InfluencerList from '../InfluencerList';
import PlacementSummaryTable from './PlacementSummaryTable';
import BudgetOverview from '../BudgetOverview';
import AcceptedCreatorTable from './MediaPlan';
import InvitedCreatorTable from '../InvitedCreatorTable';
import RecommendedCreators from '../RecommendedCreators';
import CreatorApplicationsPage from '../../../../pages/creators/applications';
import CreatorApplicationsTable from '../CreatorApplicationsTable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';


const PaidInfluencerCampaignDetail = ({
  campaignData,
  influencers,
  placements,
  applications,
  spentBudget, // number: total spent budget
}) => {

    const [viewAcceptedOpen, setViewAcceptedOpen] = useState(false);
    const [viewInvitedOpen, setViewInvitedOpen] = React.useState(false);
    const [viewApplicationsOpen, setViewApplicationsOpen] = React.useState(true);
    const [viewMediaPlanOpen, setViewMediaPlanOpen] = React.useState(false);
    const [totalApplications, setTotalApplications] = React.useState(0);
    const [applicationData, setApplicationData] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setApplicationData(applications);
        }, [applications]);

    
    const getApplications = async () => {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
    
          if (user) {
            const idToken = await user.getIdToken();
            const response = await axios.post(
              'https://ostendo.nucleotto.com/api/application/list',
              { campaignId: campaignData._id },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${idToken}`,
                },
              }
            );
            const applicationsResponse = response.data.applications || [];
            setApplicationData(applicationsResponse);
          }
        } catch (error) {
          console.error('Error fetching campaigns:', error);
    
        } finally {
    
        }
      };

      const totalAcceptedProposedFees = applicationData
      .filter(app => app.status === 'Accepted')
      .reduce((acc, app) => {
        const appTotal = app.placementsAppliedTo?.reduce((sum, placement) => sum + (Number(placement.proposedFee) || 0), 0) || 0;
        return acc + appTotal;
      }, 0);

      const acceptedApplications = applicationData.filter(app => app.status === 'Accepted');
      
      let mediaPlan;
      if (acceptedApplications.length > 0) {
        mediaPlan = acceptedApplications.length;
      } else {
        mediaPlan = 0;
      };
        
    
    const toggleViewInvited = () => {
        setViewInvitedOpen((prev) => !prev);
      };

      const toggleViewApplications = () => {
        setViewApplicationsOpen((prev) => !prev);
      };

      const toggleViewMediaPlan = () => {
      navigate(`media-plan`);
      };

     const toggleViewAccepted = () => {
        setViewAcceptedOpen((prev) => !prev);
      };

      useEffect(() => {
        setTotalApplications(applications.length);
      }, [applications]);

  return (
    <Container maxWidth="xl">
    <Typography variant="h6">{campaignData.campaignName}</Typography>

        <Box sx={{ mb: 3, mt: 3 }}>
        <BudgetOverview 
        totalBudget={campaignData.totalBudget} 
        influencers={influencers}
        totalAcceptedProposedFees={totalAcceptedProposedFees}
        />

      </Box>
      <CampaignSummaryCards 
      campaignData={campaignData}
      influencers={influencers}
      totalApplications={totalApplications}
      toggleViewInvited={toggleViewInvited}
      toggleViewAccepted={toggleViewAccepted}
      toggleViewApplications={toggleViewApplications}
      toggleViewMediaPlan={toggleViewMediaPlan}
      mediaPlan={mediaPlan}
       />

       <>
       {viewMediaPlanOpen && (
        <Typography variant="h6">Media Plan</Typography>
       )}

        {viewInvitedOpen && (

        <InvitedCreatorTable
        influencers={influencers}
        />
        )}

        {viewApplicationsOpen && (

        <CreatorApplicationsTable
        influencers={influencers}
        applications={applications}
        applicationData={applicationData}
        triggerApplicationsFetch={getApplications}
        />
        )}
            
       </>
 
      <PlacementSummaryTable 
      placements={placements} />

<RecommendedCreators />  
    </Container>
  );
};

export default PaidInfluencerCampaignDetail;

import React, { useState, useEffect } from 'react';
import { 
  Drawer, 
  Badge,
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography, 
  Box, 
  IconButton, 
  AppBar, 
  Toolbar, 
  CssBaseline 
} from '@mui/material';
import { Home } from '@mui/icons-material';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';
import MenuIcon from '@mui/icons-material/Menu'; // Hamburger icon
import { useNavigate, Link, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import InterpreterModeTwoToneIcon from '@mui/icons-material/InterpreterModeTwoTone';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';
import WorkHistoryTwoToneIcon from '@mui/icons-material/WorkHistoryTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';

const drawerWidth = 280;

const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [offers, setOffers] = useState([]);
  const location = useLocation(); // Get the current location
  const user_type = localStorage.getItem('user_type');
  const navigate = useNavigate();
  
  // Check if the screen size is small (for mobile responsiveness)
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    if (!user_type) {
      navigate('/logout');
    };
    if (user_type === 'creator') {
      findOfferedJobs();
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function getDashboardTitle(user_type) {
    if (user_type === 'creator') {
      return 'For Creators';
    } else if (user_type === 'admin') {
      return 'Admin';
    } else if (user_type === 'brand_admin') {
      return 'For Brands';
    } else {
      return 'Dashboard';
    }
  }

  const findOfferedJobs = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/application/list-offered?creatorId=${user.uid}`,
          {
            headers: {
             'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setOffers(response.data.applications || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      
    }
  
  };

  const drawerContent = (
    <Box>
      <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 0 }}>
        CrowdSwell 
      </Typography>
      <Typography variant="subtitle2" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
      {getDashboardTitle(user_type)}
      </Typography>
      <List>
        {[
       ...(user_type === 'creator' ? [{ text: 'Campaigns', icon: <CampaignTwoToneIcon />, path: '/creators/dashboard' }] : []),
       ...(user_type === 'creator' ? [{
        text: 'Applications',
        icon: (
          <Badge badgeContent={offers.length} color="primary">
            <ExtensionTwoToneIcon />
          </Badge>
        ),
        path: '/creators/applications'
      }] : []),
     
     

       ...(user_type === 'admin' || user_type === 'brandUser' || user_type === 'brandAdmin' ? [{ text: 'Campaigns', icon: <CampaignTwoToneIcon />, path: '/campaigns' }] : []),

       ...(user_type === 'admin' || user_type === 'brandAdmin' || user_type ==='brandUser' ? [{ text: 'Creators', icon: <InterpreterModeTwoToneIcon />, path: '/users/creators' }] : []),
       ...(user_type === 'admin' ? [{ text: 'Brands', icon: <ApartmentTwoToneIcon />, path: '/brands' }] : []),
       ...(user_type === 'admin' || user_type === 'brandAdmin' ? [{ text: 'Users', icon: <PeopleAltTwoToneIcon />, path: '/users' }] : []),


        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            sx={{
              padding: '12px 16px',
              borderRadius: '10px',
              backgroundColor: location.pathname === item.path ? '#f3f4f6' : 'inherit', // Highlight the active item
              '&:hover': {
                backgroundColor: '#f3f4f6',
              },
            }}
          >
            <ListItemIcon sx={{ color: item.active ? '#1976d2' : '#000' }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* AppBar for mobile view */}
      {isMobile && (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              CrowdSwell
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Permanent drawer for larger screens */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            padding: '24px',
            backgroundColor: '#fff',
          },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Spacer for content shift when sidebar is open */}
      {isMobile && <Toolbar />}
    </Box>
  );
};

export default Sidebar;

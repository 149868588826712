// PaidInfluencersDashboard.jsx
import React, {useState, useEffect} from 'react';
import { 
  Box,
  Container, 
  Typography,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Grid2,
  CircularProgress

 } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import YourAIAssessment from './YourAIAssessment';
import PlacementSummaryTable from './PlacementSummaryTable';
import CampaignSettings from './campaignSettings';
import { createApplication } from '../../../../../api/applicationApi';
import ApplicationDataTable from './ApplicationSummaryTable';
import JobSummaryCards from './JobSummaryCards';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import TaskTimeline from '../TaskTimeline';
import ChatPanel from '../../../campaigns/jobScreen/ChatPanel';
import TaskPanel from '../../../campaigns/jobScreen/TaskPanel';
import CreatorActionSelector from '../../../campaigns/jobScreen/CreatorActionSelector';
import ApplicationFiles from '../../../campaigns/jobScreen/ApplicationFiles';
import CalendarPanel from '../../../campaigns/jobScreen/CalendarPanel';


const PaidInfluencerJobDetailCreator = ({
  campaignData,
  influencers,
  placements,
  applications,
  spentBudget
}) => {

    const [viewAcceptedOpen, setViewAcceptedOpen] = useState(false);
    const [viewInvitedOpen, setViewInvitedOpen] = React.useState(false);
    const [viewApplicationsOpen, setViewApplicationsOpen] = React.useState(true);
    const [viewMediaPlanOpen, setViewMediaPlanOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [applyModalOpen, setApplyModalOpen] = useState(false);
    const [negotiationMessage, setNegotiationMessage] = useState('');
    const [applicationPlacements, setApplicationPlacements] = useState([]);
    const [isApplicationLoading, setIsApplicationLoading] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState('');
    const [applicationData, setApplicationData] = useState({});
    const [tasks, setTasks] = useState([]);
    const [tasksLoading, setTasksLoading] = useState(true);
    const [viewFilesOpen, setViewFilesOpen] = React.useState(true);
    const [refreshFiles, setRefreshFiles] = useState(false);


    const handleOpenApplyModal = () => setApplyModalOpen(true);
    const handleCloseApplyModal = () => setApplyModalOpen(false);
    const navigate = useNavigate();

    useEffect(() => {
      getTasks();
    }, []);

    const getTasks = async () => {
    
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const jobId = campaignData._id;
        const type = "Creator";
        if (user) { 
          const idToken = await user.getIdToken();
          const response = await axios.get(`https://ostendo.nucleotto.com/api/tasks/get?jobId=${jobId}&type=${type}`,
          
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
              },
            }
          );  
          setTasks(response.data.tasks);
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setTasksLoading(false);
      } 
    }

    const getApplication = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const accessToken = await user.getIdToken();
          const jobId = campaignData._id;
          const response = await axios.get(
            `https://ostendo.nucleotto.com/api/application/creator-applications?creatorId=${user.uid}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const applications = response.data.applications || [];
          const filteredApplications = applications.filter(application => application.campaignId === jobId);
          setApplicationData(filteredApplications); 
          const acceptedApplicationsData = filteredApplications.filter(app => app.status === 'Accepted')
          setApplicationData(acceptedApplicationsData)
   
        }
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      } finally {
      
      }
    };
  

    const triggerTaskRefresh = async () => {
      await getTasks();
      await getApplication();
    };

    
    const toggleViewInvited = () => {
        setViewInvitedOpen((prev) => !prev);
      };

      const toggleViewApplications = () => {
        setViewApplicationsOpen((prev) => !prev);
      };

      const toggleViewMediaPlan = () => {
      navigate(`media-plan`);
      };

     const toggleViewAccepted = () => {
        setViewAcceptedOpen((prev) => !prev);
      };

      const toggleApplicationFiles = () => {
        setViewFilesOpen((prev) => !prev);
      };

      const handleFileRefresh = () => {
        setRefreshFiles(prev => !prev);
      };




    useEffect(() => {
      if (applications && applications.length) {
       setApplicationStatus(applications[0].status);
       setApplicationData(applications[0]);
      }
    }, [applications]);


      useEffect(() => {
        if (placements && placements.length) {
          setApplicationPlacements(
            placements.map((p) => ({
              _id: p._id,
              selected: true,
              proposedFee: p.fee || ''
            }))
          );
        }
      }, [placements]);


      const handlePlacementChange = (id, field, value) => {
        setApplicationPlacements((prev) =>
          prev.map((item) =>
            item._id === id ? { ...item, [field]: value } : item
          )
        );
      };


      const triggerApplicationRefresh = async () => {
        await getApplication();
      } 
      


  return (

      <Container maxWidth="xl">

      <Box sx={{ mb: 3, mt: 3}}>

        
        <JobSummaryCards 
          campaignData={campaignData}
          applications={applications}
          tasks={tasks} 
          toggleApplicationFiles={toggleApplicationFiles}/>

      </Box>
    {viewFilesOpen && (
      <Box sx={{ mb: 3, mt: 3 }}>
        <ApplicationFiles 
           application={applicationData}
           refresh={refreshFiles}/>
        </Box>
    )}
      

      

      <Box sx={{  display: 'flex', justifyContent: 'space-between', mb: 3, mt: 3 }}>

      {tasksLoading ? (
         <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '90%', ml: 10, mt: 20 }}>
            <Box
                    sx={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 1300, // ensures it appears on top
                    }}
                  >
                    <CircularProgress />
                  </Box>
    
       </Grid2> 
      ) : (

        <>
    
        <Grid2 container spacing={2} width="100%">
        
          <Grid2 item width="63%" xs={12} md={4}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, height: '60vh', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
                    >
                          <Box sx={{ flexGrow: 1 }}>
        <ChatPanel 
        application={applicationData}
        /></Box>
        </Paper></Grid2>

        <Grid2 item width="35%" xs={12} md={4}>
                    <Paper
                      elevation={3}
                      sx={{ p: 2, height: '60vh', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
                    >
         <CreatorActionSelector 
         applicationId={applicationData._id}
         triggerApplicationRefresh={triggerApplicationRefresh} 
         triggerFileRefresh={handleFileRefresh} />
        <TaskPanel taskData={tasks}
          triggerTaskRefresh={triggerTaskRefresh}
          application={applicationData} />
       

             
        </Paper></Grid2> </Grid2>
        
     
      </>
      )}
   
      </Box>

      <Box sx={{ mb: 3, mt: 3 }}>
         <CampaignSettings 
        campaignData={campaignData}
        />

        </Box>



   
    

 

  

    </Container>
  );
};

export default PaidInfluencerJobDetailCreator;

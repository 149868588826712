// BudgetOverview.jsx
import React from 'react';
import { Box, Typography, LinearProgress, Paper } from '@mui/material';

const BudgetOverview = ({ totalBudget, totalAcceptedProposedFees }) => {

  
  const progress = totalBudget ? Math.min((totalAcceptedProposedFees / totalBudget) * 100, 100) : 0;

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Budget Overview
      </Typography>
      <Typography variant="body1">
        Total Budget: ${totalBudget || 0}
      </Typography>
      <Typography variant="body1">
        Accepted Budget: ${totalAcceptedProposedFees || 0}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          {Math.round(progress)}% of budget spent
        </Typography>
      </Box>
    </Paper>
  );
};

export default BudgetOverview;

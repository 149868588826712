import React from 'react';
import { Box, Paper, 
    Typography,  
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
     } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const JobsCard = ({ title, count, onClick }) => {
  return (
    <Paper
      elevation={3}
      onClick={onClick}
      sx={{
        padding: 2,
        cursor: 'pointer',
        flex: '1 1 150px',
        textAlign: 'center',
        '&:hover': { backgroundColor: 'grey.100' }
      }}
    >
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="h4" sx={{ mt: 1 }}>
        {count || 0}
      </Typography>
    </Paper>
  );
};

const JobSummaryCards = ({ tasks, applications, campaignData, toggleViewInvited, toggleViewAccepted, toggleViewApplications, toggleApplicationFiles }) => {
  const navigate = useNavigate();
  const jobId = campaignData._id;

  // Assuming these numeric fields are available in campaignData
  const tasksPending = tasks?.filter(task => task.status === 'Pending').length || 0;
  const tasksCompleted = tasks?.filter(task => task.status === 'Completed').length || 0;
  const tasksTotal = tasks?.length || 0;
  



  return (
    <>
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 3 }}>
      <JobsCard
        title="Tasks Pending"
        count={tasksPending}
        onClick={toggleViewInvited}
        />
      <JobsCard
        title="Tasks Completed"
        count={tasksCompleted}
       // onClick={() => navigate(`/campaigns/detail/${jobId}/accepted`)}
        onClick={toggleViewAccepted}
      />
       <JobsCard
        title="Total Tasks"
        count={tasksTotal}
        onClick={toggleViewApplications}
      />
       <JobsCard
        title="Files"
        count={tasksTotal}
        onClick={toggleApplicationFiles}
      />

    </Box>


</>

  );
};

export default JobSummaryCards;

// PaidInfluencersDashboard.jsx
import React, {useState} from 'react';
import { Box, Container } from '@mui/material';
import CampaignSummaryCards from './CampaignSummaryCards';
import InfluencerList from '../InfluencerList';
import PlacementSummaryTable from './PlacementSummaryTable';
import BudgetOverview from '../BudgetOverview';
import AcceptedCreatorTable from '../PaidInfluencers/MediaPlan';
import InvitedCreatorTable from '../InvitedCreatorTable';


const ProductSeedingCampaignDetail = ({
  campaignData,
  influencers,
   // number: total spent budget
}) => {

    const [viewAcceptedOpen, setViewAcceptedOpen] = useState(false);
    const [viewInvitedOpen, setViewInvitedOpen] = React.useState(false);
    
    const toggleViewInvited = () => {
        setViewInvitedOpen((prev) => !prev);
      };

     const toggleViewAccepted = () => {
        console.log('THE TOGAL VIEW  ACCEPTED WAS PRESSED')
        setViewAcceptedOpen((prev) => !prev);
      };

  return (
    <Container maxWidth="lg">
        <Box sx={{ mb: 3, mt: 3 }}>
        <BudgetOverview 
        totalBudget={campaignData.totalBudget} 
        influencers={influencers}
        />
      </Box>
      <CampaignSummaryCards 
      campaignData={campaignData}
      influencers={influencers}
      toggleViewInvited={toggleViewInvited}
      toggleViewAccepted={toggleViewAccepted}
       />

       <>
       {viewAcceptedOpen && (

        <AcceptedCreatorTable
        influencers={influencers}
        />
       )}
        {viewInvitedOpen && (

        <InvitedCreatorTable
        influencers={influencers}
        />
        )}
       
       </>
 
     

    </Container>
  );
};

export default ProductSeedingCampaignDetail;

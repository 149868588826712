import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const MetaRedirect = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const error = urlParams.get('error');
  const errorReason = urlParams.get('error_reason');
  const errorDescription = urlParams.get('error_description');
  const linkUrl = `/welcome/connect?code=${code || ''}&error=${error || ''}&errorReason=${errorReason || ''}&errorDescription=${errorDescription || ''}`;


  useEffect(() => {
    
    if (code) {
      localStorage.setItem('code', code);
    }

    // If there's an opener (parent window), send the data back (pop-up case)
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'meta_auth_response',
          code,
          error: error ? { error, errorReason, errorDescription } : null,
        },
        window.location.origin
      );
      // Close the popup window after sending the message
      window.close();
    } else {
      // Fallback case: Navigate back to SocialConnectPage with the code
      console.log('No opener window found. Redirecting to SocialConnectPage with code.');
      window.location.href = `https://crowdswell.nucleotto.com/welcome/connect?code=${code || ''}&error=${error || ''}&errorReason=${errorReason || ''}&errorDescription=${errorDescription || ''}`;
    }
  }, [navigate]);
  
  
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <p>Processing Meta authentication...</p>
      <p>If this window doesn't close or redirect automatically, please wait or 
        <Link to={linkUrl}>click here</Link> to continue.

        </p>
    </div>
  );
};

export default MetaRedirect;
import React, { useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Box, Container, CssBaseline, TextField, Button, Typography } from '@mui/material';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import Sidebar from '../../components/navigation/MainNav';
import { postMessage } from '../../api/messaging';



const ReviewCreative = () => {
  const navigate = useNavigate();
  const user_type = localStorage.getItem('user_type');
  const [searchParams] = useSearchParams(); 
  const videoUrl = searchParams.get('url'); // Get the video URL from the location object
  const applicationId = searchParams.get('app'); // Get the video URL from the location object
  const campaignId = searchParams.get('cid'); // Get the video URL from the location object
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false); // Track video playing state
  const [comment, setComment] = useState(''); // Comment input
  const [reviewPosts, setReviewPosts] = useState([]); // Array to store review posts
  const playerRef = useRef(null); // R

  const handleProgress = (state) => {
    setPlayedSeconds(state.playedSeconds);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSaveReviewPost = () => {
    if (comment.trim() !== '') {
      const newReviewPost = {
        timestamp: playedSeconds.toFixed(2),
        comment: comment.trim(),
      };
      setReviewPosts([...reviewPosts, newReviewPost]);
      setComment(''); // Clear the comment input
    }
  };
  
  const handleSubmitReview = async () => {

    const flattenedReviewPosts = reviewPosts
      .map((post) => `Timestamp: ${post.timestamp} seconds, Comment: ${post.comment}`)
      .join('\n');

    const messageData = {
      applicationId: applicationId,
      campaignId: campaignId,
      text: flattenedReviewPosts,
      from: "Admin", // Or get from user context
      to: "Creator"
    };



      try {
          await postMessage(messageData);
      } catch (error) {
          console.error("Error sending message:", error);
      } finally {
        navigate(-1);
      }




    console.log('Review Posts:', reviewPosts);
  };

  return (
      <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Sidebar />
     <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Container maxWidth="xxl">
              
              <Box>
                <ProfileAvatar />
           </Box>


      <ReactPlayer
        url={videoUrl}
        controls
        onProgress={handleProgress}
        onDuration={handleDuration}
        width="900px"
        height="600px"
      />

   
        <p>Played Time: {playedSeconds.toFixed(2)} seconds</p>
        <p>Duration: {duration.toFixed(2)} seconds</p>

        <TextField
            
            multiline
            rows={4}
            fullWidth
            value={comment}
            onChange={handleCommentChange}
            sx={{ mt: 2 }}
            label="Add a comment and it will automatically save it with a timestamp"
          />
          <Button variant="contained" onClick={handleSaveReviewPost} sx={{ mt: 2 }}>
            Comment
          </Button>

          {/* Display Review Posts */}
          <Box sx={{ mt: 3 }}>
            {reviewPosts.map((post, index) => (
              <Box key={index} sx={{ mb: 2, border: '1px solid #ccc', p: 2 }}>
                <Typography variant="subtitle1">Timestamp: {post.timestamp} seconds</Typography>
                <Typography variant="body1">Comment: {post.comment}</Typography>
              </Box>
            ))}
          </Box>

          <Button variant="contained" onClick={handleSubmitReview} sx={{ mt: 2 }}>
            Submit Your Review
          </Button>

 </Container></Box></Box>

  );
};

export default ReviewCreative;
